import { KeyValue } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "enumAsArray"
})
export class EnumAsArrayPipe implements PipeTransform {
    transform<K, V>(
        input: Record<string | number | symbol, V> | ReadonlyMap<K, V>
    ): Array<KeyValue<string | number | symbol, V>> {
        return Object.entries(input).map(([key, value]) => ({
            key,
            value
        }));
    }
}
