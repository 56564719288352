import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-agree-to-share-info",
    templateUrl: "./agree-to-share-info.component.html",
    styleUrls: ["./agree-to-share-info.component.scss"]
})
export class AgreeToShareInfoComponent implements OnInit {
    @Output() btnClose = new EventEmitter();
    @Output() btnNext = new EventEmitter();
    @Output() agreeEvent = new EventEmitter<boolean>();

    public agree!: boolean;

    constructor() {}

    ngOnInit(): void {}

    onClose() {
        this.btnClose.emit();
    }

    onNext() {
        this.btnNext.emit();
    }

    onAgree($event: any) {
        this.agree = $event.target.checked;
        this.agreeEvent.emit(this.agree);
    }
}
