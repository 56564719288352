import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { fadeIn } from "src/app/common/animations";

@Component({
    selector: "app-modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
    animations: [fadeIn("0.2s")]
})
export class ModalComponent implements OnInit {
    @Output() public modalClosed: EventEmitter<void> = new EventEmitter<void>();

    @Input() iconFileName: string = "";
    @Input() title: string = "";
    @Input() userClosable: boolean = true;

    constructor(private domSanitizer: DomSanitizer) {}

    ngOnInit(): void {}

    public close(): void {
        if (this.userClosable) {
            this.modalClosed.emit();
        }
    }
}
