import { Component, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { fadeIn } from "src/app/common/animations";
import { PillarService } from "src/app/shared/services/pillar.service";
import { AnalyticsService } from "../../services/analytics.service";
import { PreviousRouteService } from "../../services/previous-route.service";

@UntilDestroy()
@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    animations: [fadeIn("0.3s")]
})
export class HeaderComponent implements OnInit {
    public showNav: boolean = false;
    public showHamburger: boolean = true;
    public showX: boolean = false;

    constructor(
        private router: Router,
        private pillarService: PillarService,
        private previousRouteService: PreviousRouteService,
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit(): void {
        this.pillarService
            .getCurrentPillarId()
            .pipe(untilDestroyed(this))
            .subscribe((_) => {
                this.closeNav();
            });
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                const currentRoute = event.url;

                // check if route has rules
                if (currentRoute.includes("rules") || currentRoute.includes("register")) {
                    this.showHamburger = false;
                    this.showX = false;

                    const params = new URLSearchParams(document.location.search);

                    // check if source equals email
                    if (params.get("source") !== "email" && currentRoute.includes("rules")) {
                        this.showX = true;
                    }
                } else {
                    this.showHamburger = true;
                    this.showX = false;
                }
            }
        });
    }

    public openNav(): void {
        this.showNav = true;
        this.analyticsService.trackInteraction("HEADER_OPEN_NAV");
    }

    public closeNav(): void {
        this.showNav = false;
    }

    public closeRules() {
        this.analyticsService.trackInteraction("NAV_MENU_CLOSE_RULES");
        this.router.navigate([this.previousRouteService.getPreviousUrl()]);
    }
}
