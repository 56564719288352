import { AbstractControl, ValidationErrors } from "@angular/forms";

export class CustomValidators {
    static emailRegex = new RegExp(/^[a-zA-Z_0-9-'\+~]+(\.[a-zA-Z_0-9-'\+~]+)*@([a-zA-Z_0-9-]+\.)+[a-zA-Z]{2,7}$/);

    static email(control: AbstractControl): ValidationErrors | null {
        const email: string = control.value;
        const emailRegExp = CustomValidators.emailRegex;

        if (!emailRegExp.test(email)) {
            return {
                email: "Please enter a valid email address"
            };
        }

        return null;
    }

    static phoneNumber(control: AbstractControl): ValidationErrors | null {
        const phoneNumber: string = control.value;
        const phoneRegExp = new RegExp(/^\(?([2-9]\d{2})\)?[- ]([2-9]\d{2})[- ](\d{4})$/);

        if (phoneNumber && !phoneRegExp.test(phoneNumber)) {
            return {
                phoneNumber: "Please enter a valid phone number."
            };
        }

        return null;
    }
}
