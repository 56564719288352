import { Injectable } from "@angular/core";
import { AnalyticsInteraction, AnalyticsInteractionsMap } from "src/app/common/models/AnalyticsInteraction.interface";
import { UserSession } from "src/app/common/models/UserSession.interface";
import { SessionService } from "./session.service";

declare const window: any;

@Injectable({
    providedIn: "root"
})
export class AnalyticsService {
    private interactions: AnalyticsInteractionsMap | undefined;
    private currentSession: UserSession | undefined;

    constructor(private sessionService: SessionService) {
        this.sessionService.getSession().subscribe((session) => {
            this.currentSession = session;
        });
    }

    public trackPageView(pageName: string, url?: string, extraArgs?: Record<string, any>): void {
        if (!pageName) {
            return;
        }

        if (window.gtag) {
            const metaData = this.getDefaultMetaData();

            window.gtag("event", "page_view", {
                page_title: pageName,
                page_location: url || window.location.href,
                ...extraArgs,
                ...metaData
            });
        }
    }

    public trackCustomEvent(eventName: string, extraArgs?: Record<string, any>): void {
        const metaData = this.getDefaultMetaData();

        if (window.gtag) {
            window.gtag("event", eventName, { ...extraArgs, ...metaData });
        }
    }

    public async trackInteraction(interactionId: string, extraArgs?: Record<string, any>): Promise<void> {
        const interaction = await this.getInteractionById(interactionId);

        if (!interaction || !interaction.action) {
            return;
        }

        const metaData = this.getDefaultMetaData();

        if (window.gtag) {
            window.gtag("event", interaction.action, {
                event_category: interaction.category,
                event_label: interaction.label,
                ...extraArgs,
                ...metaData
            });
        }
    }

    private async getInteractionById(interactionId: string): Promise<AnalyticsInteraction | undefined> {
        const interactions = await this.getAnalyticsFromConfig();

        if (!interactions) {
            return;
        }

        const key = Object.keys(interactions).find((id) => {
            return id === interactionId;
        });

        if (key) {
            return interactions?.[key];
        }

        return;
    }

    private async getAnalyticsFromConfig(): Promise<AnalyticsInteractionsMap | undefined> {
        if (this.interactions) {
            return this.interactions;
        }

        try {
            const config = await import("../../configs/analytics-interactions.config");

            if (config) {
                this.interactions = config.interactions || undefined;
                return this.interactions;
            } else {
                return undefined;
            }
        } catch (e) {
            console.error("No Interactions could be loaded");
            return undefined;
        }
    }

    private getDefaultMetaData(): Record<string, any> {
        const metaData: any = {};

        if (this.currentSession?.eventName) {
            metaData.location_name = this.currentSession.eventName;
        }

        return metaData;
    }
}
