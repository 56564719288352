import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-sentry-test",
    templateUrl: "./sentry-test.component.html",
    styleUrls: ["./sentry-test.component.scss"]
})
export class SentryTestComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
        throw new Error("Sentry test error thrown");
    }
}
