import { Injectable } from "@angular/core";
import { delay, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class LoadingService {
    private _loadingStatus: Subject<boolean> = new Subject<boolean>();

    constructor() {}

    public getLoadingStatus(): Observable<boolean> {
        return this._loadingStatus.pipe(delay(0));
    }

    public setIsLoading(): void {
        this._loadingStatus.next(true);
    }

    public setFinishedLoading(): void {
        this._loadingStatus.next(false);
    }
}
