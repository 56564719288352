import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "src/app/shared/shared.module";
import { RegisterComponent } from "./register.component";

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, SharedModule],
    declarations: [RegisterComponent],
    exports: [RegisterComponent]
})
export class RegisterModule {}
