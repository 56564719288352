import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { finalize, Observable, switchMap, tap } from "rxjs";
import { LoadingService } from "src/app/shared/services/loading.service";
import { ProgressService } from "src/app/shared/services/progress.service";
import { SessionService } from "src/app/shared/services/session.service";

@Injectable({
    providedIn: "root"
})
export class SessionWithProgressGuard implements CanActivate {
    constructor(
        private router: Router,
        private progressService: ProgressService,
        private sessionService: SessionService,
        private loadingService: LoadingService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.sessionService.getSession().pipe(
            tap(() => {
                this.loadingService.setIsLoading();
            }),
            switchMap(async (session) => {
                if (!session || !session.email) {
                    this.goHome();
                    return false;
                }

                await this.progressService.setProgressFromSession();

                const passportComplete = await this.progressService.isPassportComplete();

                if (passportComplete) {
                    this.router.navigate(["", "complete"]);
                    return false;
                }

                return true;
            }),
            finalize(() => {
                this.loadingService.setFinishedLoading();
            })
        );
    }

    private goHome(): void {
        this.router.navigate([""]);
    }
}
