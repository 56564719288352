import { Component, OnInit } from "@angular/core";
import { ProgressService } from "src/app/shared/services/progress.service";

@Component({
    selector: "app-clear-progress",
    templateUrl: "./clear-progress.component.html",
    styleUrls: ["./clear-progress.component.scss"]
})
export class ClearProgressComponent implements OnInit {
    constructor(private progressService: ProgressService) {}

    ngOnInit(): void {
        this.progressService.resetProgress();
    }
}
