<div class="page">
    <div class="content">
        <div class="logo">
            <img class="logo__img" src="assets/images/logos/logo-toyota-passport.svg" alt="Toyota Logo" />
        </div>

        <h2 class="large-heading text--4xl text--centered uppercase">You've<br>Completed</h2>
    </div>
    <img class="image" src="assets/images/complete/car.jpg" alt="Red car view from driver-side front">

        <p class="lead text--lg text--centered">You're now entered in for a chance to win a 2023 Toyota Crown.</p>
        <p class="text text--sm text--centered uppercase">PLUS PLATINUM VEHICLE SERVICE AGREEMENT FROM Toyota Financial Services FOR ONE FULL YEAR!</p>  
        <p class="info text text--centered">You will receive a recap email with your entry confirmation.</p>
    <div class="c-button">
        <app-button text="Explore Toyota.com" [resetAfterEvent]="true" (buttonClick)="handleButtonClick()"></app-button>
    </div>
    <div class="red-bar"></div>
    <div class="disclaimer">
        <p>NO PURCHASE NECESSARY TO ENTER OR WIN. Ends 5/20/23. Must be a permanent legal resident of the U.S. or DC (excluding HI, AK, PR), legal age of majority & at least 18 years of age. Void in HI, AK, PR & where prohibited. <a href="/rules">Click here</a> for Official Rules & complete details. Prize images are for illustrative purposes only. Actual prize may vary.</p>
    </div>
</div>

