import { Component, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import SwiperCore, { EffectCoverflow, Pagination, Swiper, SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";

SwiperCore.use([Pagination, EffectCoverflow]);

@UntilDestroy()
@Component({
    selector: "app-how-it-works",
    templateUrl: "./how-it-works.component.html",
    styleUrls: ["./how-it-works.component.scss"]
})
export class HowItWorksComponent {
    @ViewChild("swiperSlider", { static: false }) swiperSlider?: SwiperComponent;

    private currentX: number = 0;

    private initialLoad: boolean = true;

    swiperConfig: SwiperOptions = {
        pagination: { clickable: true },
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        loopedSlides: 3,
        loopFillGroupWithBlank: false,
        slidesOffsetAfter: 0,
        effect: "coverflow",
        coverflowEffect: {
            rotate: 0,
            depth: 0,
            slideShadows: false
        }
    };

    constructor(private router: Router, private analyticsService: AnalyticsService, private elementRef: ElementRef) {}

    public goToDashboard(): void {
        this.analyticsService.trackInteraction("HOW_IT_WORKS_DASHBOARD_BUTTON");
        this.router.navigate(["/", "dashboard"]);
    }

    public trackPrevSlide(): void {
        this.analyticsService.trackInteraction("HOW_IT_WORKS_PREV_SLIDE");
    }

    public trackNextSlide(): void {
        this.analyticsService.trackInteraction("HOW_IT_WORKS_NEXT_SLIDE");
    }

    public trackBreadcrumbSlideChange(swiper: Swiper): void {
        if (swiper) {
            const newIndex = swiper.realIndex + 1;
            this.analyticsService.trackCustomEvent("Go To Slide", {
                event_category: "How It Works",
                event_label: `Go To Slide ${newIndex}`
            });
        }
    }

    public onSlideChange(swiperObject: [Swiper]): void {
        if (this.initialLoad) {
            this.initialLoad = false;
            return;
        }

        const [swiper] = swiperObject;

        if (this.currentX !== swiper.touches.currentX) {
            if (swiper.touches.diff > 0) {
                this.trackPrevSlide();
            } else if (swiper.touches.diff < 0) {
                this.trackNextSlide();
            }

            this.currentX = swiper.touches.currentX;
        } else {
            this.trackBreadcrumbSlideChange(swiper);
        }
    }
}
