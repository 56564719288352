import { Injectable } from "@angular/core";
import { catchError, EMPTY, finalize, from, merge, mergeMap, Observable, tap, toArray } from "rxjs";
import { imageAssets, videoAssets } from "src/app/configs/preload.config";
import { LoadingService } from "./loading.service";

@Injectable({
    providedIn: "root"
})
export class PreloadService {
    private preloadedAssets: PreloadableAsset[] = [];

    constructor(private loadingService: LoadingService) {}

    public preloadImage(path: string) {
        return new Promise<HTMLImageElement>((resolve, reject) => {
            const img = document.createElement("img");
            img.onload = () => {
                resolve(img);
            };
            img.onerror = () => {
                reject(new Error("Image asset failed to load: " + path));
            };
            img.src = path;
        });
    }

    public preloadVideo(path: string) {
        return new Promise<HTMLVideoElement>((resolve, reject) => {
            const video = document.createElement("video");
            video.oncanplaythrough = () => {
                resolve(video);
            };
            video.onerror = () => {
                reject(new Error("Video asset failed to load: " + path));
            };
            video.src = path;
        });
    }

    /**
     * Preloads all assets defined in the config
     * src/app/configs/preload.config.ts
     */
    public preloadAssets(): Observable<PreloadableAsset[]> {
        this.loadingService.setIsLoading();

        return merge(
            from(imageAssets).pipe(mergeMap((imagePath) => this.preloadImage(imagePath))),
            from(videoAssets).pipe(mergeMap((videoPath) => this.preloadVideo(videoPath)))
        ).pipe(
            tap((asset: PreloadableAsset) => {
                this.preloadedAssets.push(asset); // Hold asset in memory
            }),
            catchError((err) => {
                // Do not halt the app if a preload fails - just continue
                console.warn(err?.message || "Asset preload error");
                return EMPTY;
            }),
            toArray(), // Combine all emissions into one array once completed
            finalize(() => {
                this.loadingService.setFinishedLoading();
            })
        );
    }
}

export type PreloadableAsset = HTMLImageElement | HTMLVideoElement | HTMLAudioElement;
