<div class="c-modal">
    <div class="modal">
        <div class="modal__header">
            <button class="icon-button modal__close" (click)="onClose()">
            </button>
        </div>
        <div class="modal__body">
            
            <h3 class="title text--sm text--centered uppercase">Check the box</h3>
            <hr>
            <div class="modal-body">
                <label class="c-checkbox">
                    <input type="checkbox" (change)="onAgree($event)">
                    <span class="checkmark"></span>
                </label>
                <div class="modal-disclaimer text--sm text--centered">
                    <p>By checking the box, you agree that Toyota may collect your registration information to administer the Passport Challenge. To learn more about Toyota’s information handling practices, please review the Toyota <a href="http://www.toyota.com/support/privacy-rights" target="_blank">Privacy Policy</a>.</p>
                </div>
            </div>
            
        </div>
    </div>
    <div class="modal-background" (click)="onClose()"></div>
</div>
<div [ngClass]="{'expanding-footer-button--show': agree}" class="expanding-footer-button modal-footer-button">
    <app-button text="Next" (buttonClick)="onNext()" [disabled]="!agree" [resetAfterEvent]="true"></app-button>
</div>