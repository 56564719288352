import { Injectable } from "@angular/core";
import { Quiz } from "src/app/common/models/Quiz.interface";

@Injectable({
    providedIn: "root"
})
export class QuizLoaderService {
    private loadedQuizzes: Record<string, Quiz> = {};

    constructor() {}

    public async getQuizById(quizId: string): Promise<Quiz | null> {
        if (Object.keys(this.loadedQuizzes).includes(quizId)) {
            return this.loadedQuizzes[quizId];
        }

        try {
            const config = await import(`../../configs/quizzes/${quizId}.config.ts`);

            const quiz = config.quiz;

            if (quiz && quiz.questions.length) {
                this.loadedQuizzes[quizId] = quiz;
                return quiz;
            } else {
                return null;
            }
        } catch {
            return null;
        }
    }
}
