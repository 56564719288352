<div class="page">
    <div class="content">
        <h5 class="content__title text--lg">Thank you for your interest in Toyota</h5>

        <p class="content__copy text--md">You must be at least 18 years old and from the US to participate.</p>
    </div>
  
    <div class="footer-button">
        <app-button text="Back to Home" [resetAfterEvent]="true" (buttonClick)="goHome()"></app-button>
    </div>
</div>