import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SwiperModule } from "swiper/angular";
import { AgreeToShareInfoComponent } from "./components/agree-to-share-info/agree-to-share-info.component";
import { ButtonComponent } from "./components/button/button.component";
import { SelectComponent } from "./components/form-select/select.component";
import { HeaderComponent } from "./components/header/header.component";
import { LoadingSpinnerComponent } from "./components/loading-spinner/loading-spinner.component";
import { ModalComponent } from "./components/modal/modal.component";
import { NavComponent } from "./components/nav/nav.component";
import { SvgButtonComponent } from "./components/svg-button/svg-button.component";
import { LinkComponent } from "./link/link.component";
import { EnumAsArrayPipe } from "./pipes/enum-as-array.pipe";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { StringInListPipe } from "./pipes/string-in-list.pipe";
import { StripOutTagsPipe } from "./pipes/strip-out-tags.pipe";

@NgModule({
    declarations: [
        ButtonComponent,
        HeaderComponent,
        NavComponent,
        StripOutTagsPipe,
        ModalComponent,
        LinkComponent,
        SvgButtonComponent,
        SelectComponent,
        SafeHtmlPipe,
        StringInListPipe,
        LoadingSpinnerComponent,
        EnumAsArrayPipe,
        AgreeToShareInfoComponent
    ],
    exports: [
        ButtonComponent,
        HeaderComponent,
        StripOutTagsPipe,
        ModalComponent,
        SvgButtonComponent,
        SelectComponent,
        SwiperModule,
        SafeHtmlPipe,
        StringInListPipe,
        LoadingSpinnerComponent,
        EnumAsArrayPipe,
        AgreeToShareInfoComponent
    ],
    imports: [CommonModule, RouterModule, SwiperModule]
})
export class SharedModule {}
