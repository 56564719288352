import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "stringInList"
})
export class StringInListPipe implements PipeTransform {
    transform(value: string, completedIds: string[]): boolean {
        return completedIds.includes(value);
    }
}
