import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { catchError, of } from "rxjs";
import { EshotsEventUtility } from "src/app/common/classes/EshotsEventUtility";
import { IConsumerResponse } from "src/app/common/models/Eshots.model";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { EshotsService } from "src/app/shared/services/eshots.service";
import { LoadingService } from "src/app/shared/services/loading.service";
import { ProgressService } from "src/app/shared/services/progress.service";
import { SessionService } from "src/app/shared/services/session.service";
import { CustomValidators } from "src/app/validators/CustomValidators.validator";

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
    public form!: FormGroup;
    public showModal: boolean = false;
    public agree: boolean = false;

    public errorModalShowing: boolean = false;

    constructor(
        private router: Router,
        private progressService: ProgressService,
        private eshotsService: EshotsService,
        private sessionService: SessionService,
        private loadingService: LoadingService,
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit() {}

    private initForm(): FormGroup {
        return new FormGroup({
            email: new FormControl(null, [Validators.required, CustomValidators.email])
        });
    }

    public goToRegistrationQuestionnaire(analyticsInteractionId?: string): void {
        if (analyticsInteractionId) {
            this.analyticsService.trackInteraction(analyticsInteractionId);
        }

        this.router.navigate(["register", "questionnaire"]);
    }

    public showSignInForm(): void {
        this.analyticsService.trackInteraction("ALREADY_REGISTERED");
        this.showModal = true;
    }

    public agreeToShareInfo(): void {
        this.form = this.initForm();
    }

    async checkRegistrationStatus(): Promise<void> {
        this.progressService.resetProgress();

        this.analyticsService.trackInteraction("REGISTER_LOGIN_ATTEMPT");

        const { email } = this.form.value;

        this.loadingService.setIsLoading();
        this.eshotsService
            .getConsumer(email)
            .pipe(
                catchError((err) => {
                    this.loadingService.setFinishedLoading();
                    return of(null);
                })
            )
            .subscribe(async (res: IConsumerResponse | null) => {
                this.loadingService.setFinishedLoading();
                if (!res) {
                    this.errorModalShowing = true;
                } else {
                    if (res.consumer && res.consumer.answers && res.consumer.answers.email) {
                        const userAttendedEvents = res.consumer.events || {};

                        const eventName = EshotsEventUtility.getEventNameFromEventsData(userAttendedEvents);

                        this.sessionService.setSessionData({
                            email: res.consumer.answers.email,
                            eventName
                        });

                        await this.progressService.setProgressFromApi(res.consumer.answers);

                        this.router.navigate(["/", "how-it-works"]);
                    } else {
                        this.loadingService.setFinishedLoading;
                        throw Error("Email not found in response");
                    }
                }

                this.loadingService.setFinishedLoading();
            });
    }

    public hideErrorModal(): void {
        this.analyticsService.trackInteraction("REGISTER_CLOSE_ERROR_MODAL");
        this.errorModalShowing = false;
    }

    public hideCheckModal(): void {
        this.showModal = false;
    }

    onChangeAgree($event: any) {
        this.agree = $event.target.checked;
    }

    receiveAgreeEvent($event: any) {
        this.agree = $event;
    }
}
