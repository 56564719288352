import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { delay, filter, map } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { fromEvent } from "rxjs/internal/observable/fromEvent";
import { NetworkConnectivityService } from "./shared/network-connectivity.service";
import { AnalyticsService } from "./shared/services/analytics.service";
import { LoadingService } from "./shared/services/loading.service";
import { PreloadService } from "./shared/services/preload.service";

@UntilDestroy()
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    private resizeObservable$: Observable<Event> = new Observable<Event>();

    private routesWithoutHeader: string[] = ["/", "/complete"];

    public connectivityModal: boolean = false;

    public showHeader: boolean = true;

    public isLoading: boolean = false;
    public hasPreloaded: boolean = false;

    constructor(
        private router: Router,
        private loadingService: LoadingService,
        private networkConnectivityService: NetworkConnectivityService,
        private preloadService: PreloadService,
        private titleService: Title,
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit(): void {
        // this.provideVersionInfo();
        this.setupViewableContainerCssVar();

        this.router.events.subscribe((event: any) => {
            this.showHeader = !this.routesWithoutHeader.includes(this.router.url);
        });

        this.resizeObservable$ = fromEvent(window, "resize");
        this.resizeObservable$.subscribe((evt) => {
            this.setupViewableContainerCssVar();
        });

        this.loadingService
            .getLoadingStatus()
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                this.isLoading = value;
            });

        this.networkConnectivityService
            .checkIsConnected()
            .pipe(untilDestroyed(this))
            .subscribe((isConnected) => {
                if (!isConnected) {
                    this.connectivityModal = true;
                } else if (this.connectivityModal) {
                    this.connectivityModal = false;
                }
            });

        this.preloadService
            .preloadAssets()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.hasPreloaded = true;
            });

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                delay(0),
                map(() => this.titleService.getTitle())
            )
            .subscribe((title) => {
                this.analyticsService.trackPageView(title);
            });
    }

    private setupViewableContainerCssVar() {
        const viewableHeight = `${window.innerHeight}px`;
        const rootEl = document.documentElement;
        rootEl.style.setProperty("--app-height", viewableHeight);
    }

    // TODO: Uncomment when environment configs have been set up
    // public provideVersionInfo() {
    //     if (environment.buildNumber !== "{{buildNumber}}") {
    //         this.versionInfo = `${environment.version}-${environment.name} (${environment.buildNumber})`;
    //     } else {
    //         this.versionInfo = `${environment.version}-${environment.name}`;
    //     }
    //     console.log(this.versionInfo);
    // }
}
