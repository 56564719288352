import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "disableVoiOption"
})
export class DisableVoiOptionPipe implements PipeTransform {
    transform(keyword: string, selectedVoiKeywords: string[]): boolean {
        if (selectedVoiKeywords.length < 3) {
            return false;
        } else {
            return !selectedVoiKeywords.includes(keyword);
        }
    }
}
