import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BarcodeFormat } from "@zxing/library";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
import { fadeIn } from "src/app/common/animations";
import { PillarQRData } from "src/app/common/models/Pillar.interface";
import { StripOutTagsPipe } from "src/app/shared/pipes/strip-out-tags.pipe";

import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { PillarService } from "src/app/shared/services/pillar.service";

@UntilDestroy()
@Component({
    selector: "app-qr-modal",
    templateUrl: "./qr-modal.component.html",
    styleUrls: ["./qr-modal.component.scss"],
    providers: [StripOutTagsPipe],
    encapsulation: ViewEncapsulation.None,
    animations: [fadeIn("0.1s")]
})
export class QrModalComponent implements OnInit {
    @ViewChild("scanner", { static: true }) scanner!: ZXingScannerComponent;

    @Output() public modalClosed: EventEmitter<void> = new EventEmitter<void>();

    public pillarQrData: PillarQRData[] = [];

    scannerEnabled: boolean = false;

    public scanSuccessful = false;
    public cameraDenied = false;
    public allowedFormats: BarcodeFormat[] = [BarcodeFormat.QR_CODE];

    public errorModalShowing: boolean = false;

    constructor(
        private router: Router,
        private pillarService: PillarService,
        private analyticsService: AnalyticsService,
        private stripOutTagsPipe: StripOutTagsPipe
    ) {}

    async ngOnInit() {
        this.pillarQrData = await this.pillarService.getAllPillarQRData();

        this.scanner.permissionResponse.pipe(untilDestroyed(this)).subscribe((perm: boolean) => {
            if (!perm) this.cameraDenied = true;
            else this.cameraDenied = false;
        });

        if (!localStorage.getItem("firstVisit")) {
            alert("In order to play this game, you must accept the following prompt to access your camera.");
            localStorage.setItem("firstVisit", "true");
        }

        this.scannerEnabled = true;
    }

    scanSuccessHandler(qrValue: any) {
        // Prevent calling method again while scan is processing
        if (!this.scannerEnabled) {
            return;
        }
        if (qrValue) {
            // Should be taken to completed dashboard item
            this.scanSuccessful = true;
            this.scannerEnabled = false;

            const matchingQrData = this.pillarQrData.find((pillarQrData: PillarQRData) => {
                return pillarQrData.qrUrl === qrValue;
            });

            setTimeout(() => {
                if (matchingQrData) {
                    const formattedPillarName = this.stripOutTagsPipe.transform(matchingQrData.name);

                    this.analyticsService.trackInteraction("QR_VALID", { pillar_name: formattedPillarName });
                    this.router.navigate([matchingQrData.id]);
                } else {
                    this.analyticsService.trackInteraction("QR_INVALID");
                    this.router.navigate(["/"]);
                }

                this.closeModal(true);
            }, 500);
        }
    }

    public closeModal(skipAnalytics?: boolean) {
        if (!skipAnalytics) {
            this.analyticsService.trackInteraction("QR_CLOSE_SCANNER");
        }

        this.scannerEnabled = false;
        this.modalClosed.emit();
    }

    public hideErrorModal(): void {
        this.resetScanner();
        this.analyticsService.trackInteraction("QR_ERROR_MODAL_CLOSE");
        this.errorModalShowing = false;
    }

    private resetScanner(): void {
        this.scanSuccessful = false;
        this.scannerEnabled = true;
    }
}
