import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Pillar, PillarQRData } from "src/app/common/models/Pillar.interface";

@Injectable({
    providedIn: "root"
})
export class PillarService {
    private currentPillarId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    private pillars: Pillar[] = [];

    constructor() {}

    public setCurrentPillarId(pillarId: string): void {
        this.currentPillarId$.next(pillarId);
    }

    public getCurrentPillarId(): Observable<string | null> {
        return this.currentPillarId$.asObservable();
    }

    public async getPillarById(id: string): Promise<Pillar | undefined> {
        const pillars = await this.getAllPillars();

        const pillar = pillars.find((pillar) => {
            return pillar.id === id;
        });

        return pillar;
    }

    public async getAllPillars(): Promise<Pillar[]> {
        if (this.pillars.length) {
            return this.pillars;
        } else {
            try {
                const config = await import("../../configs/pillars.config");
                // Map QR URL
                this.pillars = config.pillars;
            } catch (err) {
                this.pillars = [];
            }
            return this.pillars;
        }
    }

    public async getEshotsIdFromPillarId(pillarId: string): Promise<string> {
        const pillar = await this.getPillarById(pillarId);

        return pillar?.eshotsId || "";
    }

    public async getPillarIndexById(pillarId: string): Promise<number> {
        const pillars = await this.getAllPillars();

        return pillars.findIndex((pillar) => {
            return pillar.id === pillarId;
        });
    }

    public async getAllPillarQRData(): Promise<PillarQRData[]> {
        const pillars = await this.getAllPillars();

        return pillars.map((pillar) => {
            return {
                id: pillar.id,
                name: pillar.name,
                qrUrl: pillar.qrUrl
            };
        });
    }
}
