import { Injectable } from "@angular/core";
import { fromEvent, interval, map, merge, Observable, of, Subject, throttle } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class NetworkConnectivityService {
    private _networkConnected$: Subject<boolean> = new Subject<boolean>();

    constructor() {
        this.checkNetworkStatus();
    }

    private setConnectionStatus(isConnected: boolean): void {
        this._networkConnected$.next(isConnected);
    }

    public checkIsConnected(): Observable<boolean> {
        return this._networkConnected$.asObservable();
    }

    private checkNetworkStatus() {
        this.setConnectionStatus(navigator.onLine);
        merge(of(null), fromEvent(window, "online"), fromEvent(window, "offline"))
            .pipe(
                throttle(() => interval(2500)),
                map(() => navigator.onLine)
            )
            .subscribe((isConnected) => {
                this.setConnectionStatus(isConnected);
            });
    }
}
