import { Component, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Pillar } from "src/app/common/models/Pillar.interface";
import { StripOutTagsPipe } from "src/app/shared/pipes/strip-out-tags.pipe";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { PillarService } from "src/app/shared/services/pillar.service";
import { ProgressService } from "src/app/shared/services/progress.service";
import Swiper, { EffectCoverflow, Pagination, SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";

Swiper.use([Pagination, EffectCoverflow]);

@UntilDestroy()
@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    providers: [StripOutTagsPipe]
})
export class DashboardComponent implements OnInit {
    @ViewChild("swiperSlider", { static: false }) swiperSlider?: SwiperComponent;

    public qrUrl: string = "";
    public currentPillarName: string = "";

    public pillars: Pillar[] = [];
    public completedQuizIds: string[] = [];
    public currentPillarId: string = "";

    public isQrModalShowing: boolean = false;

    swiperConfig: SwiperOptions = {
        pagination: { clickable: true },
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        loopFillGroupWithBlank: false,
        slidesOffsetAfter: 0,
        effect: "coverflow",
        coverflowEffect: {
            rotate: 0,
            depth: 0,
            slideShadows: false
        }
    };

    private initialLoad: boolean = true;
    private currentX: number = 0;

    constructor(
        private pillarService: PillarService,
        private progressService: ProgressService,
        private analyticsService: AnalyticsService,
        private stripTagsPipe: StripOutTagsPipe
    ) {}

    public async ngOnInit(): Promise<void> {
        this.pillars = await this.pillarService.getAllPillars();

        this.pillarService
            .getCurrentPillarId()
            .pipe(untilDestroyed(this))
            .subscribe((pillarId) => {
                this.currentPillarId = pillarId || "";

                this.onSwiperInitialize();
            });

        this.progressService
            .getCompletedQuizIds()
            .pipe(untilDestroyed(this))
            .subscribe((completedQuizIds) => {
                this.completedQuizIds = completedQuizIds;
            });
    }

    public async onSwiperInitialize(event?: any): Promise<void> {
        const firstSlide = await this.getSlideIndexById(this.currentPillarId);

        const swiperRef = this.swiperSlider?.swiperRef;

        if (swiperRef) {
            this.initialLoad = true;
            swiperRef.slideTo(firstSlide, 0);
        }
    }

    public async getSlideIndexById(pillarId: string): Promise<number> {
        if (!pillarId) {
            return 0;
        }

        return (await this.getPillarIndexById(pillarId)) || 0;
    }

    private async getPillarIndexById(id: string): Promise<number> {
        return await this.pillarService.getPillarIndexById(id);
    }

    public openModal(pillar: Pillar): void {
        if (!this.swiperSlider || !this.swiperSlider.swiperRef) {
            return;
        }

        const realIndex = this.swiperSlider.swiperRef.realIndex;
        const formattedPillarName = this.stripTagsPipe.transform(pillar.name);

        if (realIndex < this.pillars.length) {
            this.analyticsService.trackCustomEvent("Open QR Scanner", {
                event_category: "Dashboard",
                event_label: `Open QR Scanner For Pillar: ${formattedPillarName}`
            });

            this.isQrModalShowing = true;
        }
    }

    public closeModal(): void {
        this.isQrModalShowing = false;
    }

    public trackPrevSlide(): void {
        this.analyticsService.trackInteraction("DASHBOARD_PREV_PILLAR");
    }

    public trackNextSlide(): void {
        this.analyticsService.trackInteraction("DASHBOARD_NEXT_PILLAR");
    }

    public trackBreadcrumbSlideChange(swiper: Swiper): void {
        if (swiper) {
            const pillarName = this.pillars[swiper.realIndex].name || "";

            if (!pillarName) {
                return;
            }
            const formattedPillarName = this.stripTagsPipe.transform(pillarName);

            this.analyticsService.trackCustomEvent("Go To Pillar", {
                event_category: "Dashboard",
                event_label: `Go To Pillar: ${formattedPillarName}`
            });
        }
    }

    public onSlideChange(swiperObject: [Swiper]): void {
        if (this.initialLoad) {
            this.initialLoad = false;
            return;
        }

        const [swiper] = swiperObject;

        if (this.currentX !== swiper.touches.currentX) {
            if (swiper.touches.diff > 0) {
                this.trackPrevSlide();
            } else if (swiper.touches.diff < 0) {
                this.trackNextSlide();
            }

            this.currentX = swiper.touches.currentX;
        } else {
            this.trackBreadcrumbSlideChange(swiper);
        }
    }
}
