<div class="page">
    <div class="page-hero">
        <img src="assets/images/dashboard/dashboard-hero.png" alt="Graphic with red and grey rounded shapes with dotted lines">
        <div class="page-hero__content">
            <h1 class="page-header text--centered">
                <span class="text--3xl">Your</span>
                <br>
                <span class="text--3xl">Dashboard</span>
            </h1>

            <p class="text--sm instructions">
                Select to open the QR destination scanner
            </p>
        </div>
    </div>

    <div *ngIf="pillars.length" class="c-slider">
        <swiper #swiperSlider 
            class="slider slider--dashboard" 
            [config]="swiperConfig" 
            (swiper)="onSwiperInitialize($event)" 
            [loopedSlides]="pillars.length"
            (slideChange)="onSlideChange($event)"
        >
            <ng-template *ngFor="let pillar of pillars" swiperSlide>
                <div class="slide">
                    <div class="slide__background">
                        <span *ngIf="pillar.id | stringInList: completedQuizIds" class="completed-icon"></span>
                        <img class="slide__img" [src]="pillar.dashboardImage.path" alt="Athletes with American flag above 3 Toyota vehicles">
                        <div class="slide__content">
                            <h2 class="text--xl text--white slide__title" [innerHtml]="pillar.name"></h2>
                            <app-button 
                            *ngIf="!(pillar.id | stringInList: completedQuizIds); else completed" 
                            customClass="qr-button button--dark" 
                            text="Scan QR Code"
                            [resetAfterEvent]="true"
                            (buttonClick)="openModal(pillar)"></app-button>
                            <ng-template #completed>
                                <app-button customClass="qr-button qr-button--completed" text="Completed" [disabled]="true"></app-button>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </ng-template>
        </swiper>
    </div>
</div>

<app-qr-modal *ngIf="isQrModalShowing" (modalClosed)="closeModal()"></app-qr-modal>