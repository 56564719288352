import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";

@Component({
    selector: "app-svg-button",
    templateUrl: "./svg-button.component.html",
    styleUrls: ["./svg-button.component.scss"]
})
export class SvgButtonComponent implements OnDestroy {
    @Output() public buttonClick: EventEmitter<void> = new EventEmitter<void>();

    @Input() fileName: string = "";

    @Input() public disabled: boolean = false;
    @Input() public resetAfterEvent: boolean = false;

    @Input() public customClass: string = "";

    @Input() public color?: string;

    public isActive: boolean = false;

    public eventTimeout: any;

    private readonly DELAY_IN_MILLISECONDS: number = 700;

    constructor() {}

    ngOnDestroy(): void {
        if (this.eventTimeout) {
            clearTimeout(this.eventTimeout);
        }
    }

    public onButtonClicked() {
        this.isActive = true;

        this.eventTimeout = setTimeout(() => {
            this.buttonClick.emit();

            if (this.resetAfterEvent) {
                this.resetButton();
            }
        }, this.DELAY_IN_MILLISECONDS);
    }

    private resetButton() {
        this.isActive = false;
    }
}
