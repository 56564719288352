<div class="qr" [@fadeIn]>
        <div class="header header--qr">
            <app-svg-button 
            fileName="icon-close.svg" 
            customClass="icon-button--close"
            (buttonClick)="closeModal()"
        >
        </app-svg-button>
        </div>
    <div class="qr__preview">
        <zxing-scanner #scanner (scanSuccess)="scanSuccessHandler($event)" [formats]="allowedFormats"></zxing-scanner>
        <div class="qr__frames" *ngIf="!cameraDenied">
            <div class="frame frame--top-left" [ngClass]="{'frame--active': scanSuccessful}"></div>
            <div class="frame frame--top-right" [ngClass]="{'frame--active': scanSuccessful}"></div>
            <div class="frame frame--bottom-left" [ngClass]="{'frame--active': scanSuccessful}"></div>
            <div class="frame frame--bottom-right" [ngClass]="{'frame--active': scanSuccessful}"></div>
        </div>
    </div>

    <p *ngIf="!cameraDenied && this.pillarQrData" class="qr__instructions text text--white">Position QR code in this frame!</p>
    
</div>

<app-modal *ngIf="errorModalShowing" iconFileName="icon-qr-red.svg" title="Try It Again!" (modalClosed)="hideErrorModal()">
    <p>Find a correct QR code and scan it again.</p>
</app-modal>
