import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { AngularUtility } from "./app/common/classes/angular-utility";
import { SentryLoader } from "./app/common/classes/SentryLoader";
import { environment } from "./environments/environment";

const sentryLoader = new SentryLoader();

(async () => {
    await AngularUtility.initAppVersioning(environment);

    if (environment.production) {
        enableProdMode();
    }
    const bootstrapAngular = () => {
        platformBrowserDynamic()
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));
    };
    sentryLoader.initLogging(bootstrapAngular);
})();
