import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AnalyticsService } from "src/app/shared/services/analytics.service";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit, OnDestroy {
    public redAnimationActive: boolean = false;
    public whiteAnimationActive: boolean = false;

    private animationTimeout: any;

    constructor(private router: Router, private analyticsService: AnalyticsService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        if (this.animationTimeout) {
            clearTimeout(this.animationTimeout);
        }
    }

    public startJourney() {
        this.analyticsService.trackInteraction("START_JOURNEY");

        this.redAnimationActive = true;
        this.whiteAnimationActive = true;

        this.animationTimeout = setTimeout(() => {
            this.router.navigate(["", "register"]);
        }, 1500);
    }
}
