import { Pipe, PipeTransform } from "@angular/core";
import { IRegistrationQuestionnaireAnswer } from "src/app/common/models/RegistrationQuestionnaire.model";

@Pipe({
    name: "answerText"
})
export class AnswerTextPipe implements PipeTransform {
    transform(keyword: string, possibleAnswers: IRegistrationQuestionnaireAnswer[]): string {
        const answer = possibleAnswers.find((answer) => {
            return answer.keyword === keyword;
        });

        return answer?.text || "";
    }
}
