import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import {
    ExternalDataReportingRelatId,
    IConsumerResponse,
    ICreateConsumerRequest,
    ICreateConsumerResponse,
    IEvent,
    IEventDaysResponse,
    IQuizStatusRequest,
    IRelat,
    IRelatsResponse,
    IUpdateConsumerRequest,
    RelatActivity
} from "src/app/common/models/Eshots.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class EshotsService {
    constructor(private http: HttpClient, private datePipe: DatePipe) {}

    getConsumer(email: string): Observable<IConsumerResponse | null> {
        return this.http
            .get<IConsumerResponse>(`${environment.cloudFunctionsUrl}/get-consumer`, { params: { email } })
            .pipe(
                map((res: IConsumerResponse) =>
                    !res || !res.consumer || Object.keys(res.consumer).length === 0 ? null : res
                )
            );
    }

    getEventDays(): Observable<IEventDaysResponse> {
        return this.http.get<IEventDaysResponse>(`${environment.cloudFunctionsUrl}/get-event-days`);
    }

    getActiveEvents(date: Date): Observable<IEvent[]> {
        const dateKey = date.toISOString().substring(0, 10).split("-").join("");

        return this.getEventDays().pipe(
            map((events) => {
                return events.events.filter((event) => {
                    return event.eventDays[dateKey] ? true : false;
                });
            })
        );
    }

    getRelats(): Observable<IRelatsResponse[]> {
        return this.http.get<IRelatsResponse[]>(`${environment.cloudFunctionsUrl}/get-relats`);
    }

    createConsumer(request: ICreateConsumerRequest): Observable<ICreateConsumerResponse> {
        return this.http.post<ICreateConsumerResponse>(`${environment.cloudFunctionsUrl}/create-consumer`, request);
    }

    updateConsumer(request: IUpdateConsumerRequest): Observable<ICreateConsumerResponse> {
        return this.http.post<ICreateConsumerResponse>(`${environment.cloudFunctionsUrl}/update-consumer`, request);
    }

    setQuizStatus(email: string, request: IQuizStatusRequest): Observable<ICreateConsumerResponse> {
        const date = new Date();
        const req: IUpdateConsumerRequest = {
            consumers: [
                {
                    rElatId: ExternalDataReportingRelatId,
                    interactions: [
                        {
                            createDtm: this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss", "UTC") || "",
                            answers: {
                                email,
                                ...request
                            }
                        }
                    ]
                }
            ]
        };
        return this.updateConsumer(req);
    }

    getExternalDataReportingRelatId(relats: IRelatsResponse[]): number | null {
        const relatWithClientLicenseId: IRelatsResponse | null =
            relats.find((r) => r.clientLicenseId === 50114) || null;

        const externalDataReportingRelat: IRelat | null =
            relatWithClientLicenseId?.relats?.find(
                (relat: any) => relat.activity === RelatActivity.ExternalDataReporting
            ) || null;
        return externalDataReportingRelat?.rElatId || null;
    }
}
