<div class="c-modal">
    <div class="modal">
        <div class="modal__header">
            <button *ngIf="userClosable" class="icon-button modal__close" (click)="close()">
            </button>
        </div>
        <div class="modal__body">
            <div *ngIf="iconFileName" class="circle">
                <img class="icon" [src]="'assets/icons/' + iconFileName" />
            </div>
            <h3 class="title text--sm text--centered uppercase" [innerHtml]="title"></h3>
            <hr>
            <div class="copy text--sm text--centered">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    <div class="modal-background" (click)="close()"></div>
</div>