import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CompleteGuard } from "./common/guards/complete.guard";
import { QuizGuard } from "./common/guards/quiz.guard";
import { RegistrationGuard } from "./common/guards/registration.guard";
import { SessionWithProgressGuard } from "./common/guards/session-with-progress.guard";

import { ClearProgressComponent } from "./components/clear-progress/clear-progress.component";
import { CompleteComponent } from "./components/complete/complete.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { HomeComponent } from "./components/home/home.component";
import { HowItWorksComponent } from "./components/how-it-works/how-it-works.component";
import { IneligibleComponent } from "./components/ineligible/ineligible.component";
import { OfficialRulesComponent } from "./components/official-rules/official-rules.component";
import { RegisterComponent } from "./components/register/register.component";
import { RegistrationQuestionnaireComponent } from "./components/registration-questionnaire/registration-questionnaire.component";
import { SentryTestComponent } from "./components/sentry-test/sentry-test.component";

const APP_TITLE: string = "Toyota Passport Challenge";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        component: HomeComponent,
        title: "Home"
    },

    {
        path: "register",
        component: RegisterComponent,
        canActivate: [RegistrationGuard],
        title: "Register"
    },
    {
        path: "register/questionnaire",
        component: RegistrationQuestionnaireComponent,
        canActivate: [RegistrationGuard],
        title: "Registration Form"
    },
    {
        path: "how-it-works",
        component: HowItWorksComponent,
        canActivate: [SessionWithProgressGuard],
        title: "How It Works"
    },
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [SessionWithProgressGuard],
        title: "Dashboard"
    },
    {
        path: "ineligible",
        component: IneligibleComponent,
        title: "Ineligible"
    },
    {
        path: "test-sentry",
        component: SentryTestComponent
    },
    {
        path: "complete",
        component: CompleteComponent,
        canActivate: [CompleteGuard],
        title: "Complete"
    },
    {
        path: "clear-progress",
        component: ClearProgressComponent
    },
    {
        path: "rules",
        component: OfficialRulesComponent,
        title: "Official Rules"
    },
    // Place all named routes above here
    {
        path: ":pillarId",
        loadChildren: () => import("./modules/quiz/quiz.module").then((m) => m.QuizModule),
        canActivate: [QuizGuard]
    },
    {
        path: "**",
        redirectTo: ""
    }
];

export const appRoutes = routes.filter((route) => route.path && !route.loadChildren).map((route) => route.path);

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
