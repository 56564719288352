import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-link",
    templateUrl: "./link.component.html",
    styleUrls: ["./link.component.scss"]
})
export class LinkComponent implements OnDestroy {
    @Output() public linkClicked: EventEmitter<void> = new EventEmitter<void>();

    @Input() text: string = "";
    @Input() path: string = "/";
    @Input() customClass: string = "";
    @Input() activeStateAllowed: boolean = true;

    public isActive: boolean = false;

    public external: boolean = false;

    public eventTimeout: any;

    private readonly DELAY_IN_MILLISECONDS: number = 700;

    constructor(private router: Router) {}

    ngOnDestroy(): void {
        if (this.eventTimeout) {
            clearTimeout(this.eventTimeout);
        }
    }

    public handleLinkClick() {
        this.isActive = true;

        if (this.eventTimeout) {
            clearTimeout(this.eventTimeout);
        }

        this.eventTimeout = setTimeout(() => {
            this.linkClicked.emit();
            this.goToRoute();
        }, this.DELAY_IN_MILLISECONDS);
    }

    public goToRoute(): void {
        if (!this.path) {
            return;
        } else if (this.external) {
            window.open(this.path, "_blank");
        } else {
            this.router.navigate([this.path]);
        }
    }

    public get isCurrentRoute(): boolean {
        return this.router.url === this.path && this.activeStateAllowed;
    }
}
