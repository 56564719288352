export class AngularUtility {
    static async initAppVersioning(environment: any) {
        try {
            // Get version.json from root
            let { version, buildNumber } = (await import("version.json")) as any;
            buildNumber = buildNumber || "LOCAL";

            if (!version) {
                throw new Error("Version not set ");
            }

            console.log(`****** VERSION: ${version} ******`);
            console.log(`****** BUILD: ${buildNumber} ******`);

            environment["version"] = version;
            environment["buildNumber"] = buildNumber;
        } catch (err) {
            console.error("version.json is not valid");
        }
    }
}
