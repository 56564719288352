import { IUserAttendedEvents } from "../models/Eshots.model";

export class EshotsEventUtility {
    static getEventNameFromEventsData(attendedEvents: IUserAttendedEvents): string {
        if (!attendedEvents) {
            return "";
        }

        const eventKeys = Object.keys(attendedEvents);

        if (eventKeys.length) {
            const event = attendedEvents[eventKeys[0] as keyof IUserAttendedEvents];

            if (event) {
                return event.name;
            }
        }

        return "";
    }
}
