import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { catchError, of } from "rxjs";
import { VoiType } from "src/app/common/enums/VoiType.enum";
import { ExternalDataReportingRelatId, ICreateConsumerRequest, IEvent } from "src/app/common/models/Eshots.model";
import {
    IRegistrationFormData,
    IRegistrationQuestionnaireVOIAnswer,
    RegistrationQuestionnaireAnswers
} from "src/app/common/models/RegistrationQuestionnaire.model";
import { AnalyticsService } from "src/app/shared/services/analytics.service";

import { EshotsService } from "src/app/shared/services/eshots.service";
import { LoadingService } from "src/app/shared/services/loading.service";
import { SessionService } from "src/app/shared/services/session.service";
import { CustomValidators } from "src/app/validators/CustomValidators.validator";
import { SwiperComponent } from "swiper/angular";

@UntilDestroy()
@Component({
    selector: "app-registration-questionnaire",
    templateUrl: "./registration-questionnaire.component.html",
    styleUrls: ["./registration-questionnaire.component.scss"],
    providers: [DatePipe]
})
export class RegistrationQuestionnaireComponent implements OnInit, AfterViewInit {
    registrationQuestionnaireForm!: FormGroup;
    formattedRegistrationQuestionnaireForm!: FormGroup;

    events: IEvent[] = [];

    @ViewChild("swiperSlider", { static: false }) swiperSlider?: SwiperComponent;

    answers: RegistrationQuestionnaireAnswers = new RegistrationQuestionnaireAnswers();

    public errorModalShowing!: boolean;
    public noEventsModalShowing!: boolean;
    public alreadyRegisteredModalShowing!: boolean;
    public showModal: boolean = true;
    public agree: boolean = false;
    public eventsLoaded: boolean = false;

    get voiType(): typeof VoiType {
        return VoiType;
    }

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private eshotsService: EshotsService,
        private datePipe: DatePipe,
        private sessionService: SessionService,
        private loadingService: LoadingService,
        private analyticsService: AnalyticsService
    ) {}

    ngAfterViewInit(): void {
        this.loadingService.setIsLoading();
        this.eshotsService
            .getActiveEvents(new Date())
            .pipe(
                catchError(() => {
                    this.loadingService.setFinishedLoading();
                    return of([]);
                })
            )
            .subscribe((events) => {
                if (events.length > 0) {
                    this.events = events;
                    this.registrationQuestionnaireForm = this.initRegistrationQuestionnaireForm();
                    this.initFormListeners();
                } else {
                    this.noEventsModalShowing = true;
                }
                this.eventsLoaded = true;

                this.loadingService.setFinishedLoading();
            });
    }

    ngOnInit() {}

    // Modal functions
    public hideCheckModal(): void {
        this.router.navigate(["", "register"]);
    }

    receiveAgreeEvent($event: any) {
        this.agree = $event;
    }

    public agreeToShareInfo(): void {
        this.showModal = false;
    }

    checkRegistrationStatus(): void {
        const { email } = this.registrationQuestionnaireForm.value;

        this.loadingService.setIsLoading();
        this.eshotsService.getConsumer(email).subscribe((res: any | null) => {
            this.loadingService.setFinishedLoading();

            if (!res) {
                this.formNext();
            } else {
                this.analyticsService.trackInteraction("FORM_NEXT");
                this.alreadyRegisteredModalShowing = true;
            }
        });
    }

    public closeErrorModal(): void {
        this.analyticsService.trackInteraction("FORM_CLOSE_ERROR_MODAL");
        this.errorModalShowing = false;
    }

    public closeNoEventsModal(): void {
        this.analyticsService.trackInteraction("FORM_CLOSE_ERROR_MODAL");
        this.noEventsModalShowing = false;
        this.router.navigate(["/"]);
    }

    public goToSignIn(): void {
        this.analyticsService.trackInteraction("FORM_CLOSE_ALREADY_REGISTERED_MODAL");
        this.router.navigate(["/", "register"]);
    }

    submit(): void {
        this.analyticsService.trackInteraction("FORM_SUBMIT");
        this.loadingService.setIsLoading();

        try {
            if (!this.registrationQuestionnaireForm.valid) {
                throw Error("Invalid or missing data in registration submission");
            }

            const form: IRegistrationFormData | null = this.getFlattenedFormData(this.registrationQuestionnaireForm);

            if (!form) {
                this.loadingService.setFinishedLoading();
                throw Error("Unable to parse form data");
            }

            // Add hidden_opt_in field if form opt_in equals 'yes'.
            if (form.opt_in === "yes") {
                form.hidden_opt_in = "yes";
            }

            // Add hidden_voi fields based on selected voi's.
            const voi1 = form.voi[0];
            const voi2 = form.voi[1];
            const voi3 = form.voi[2];

            if (voi1) {
                const hiddenValue = this.getHiddenVoiIdFromKeyword(voi1);

                if (hiddenValue) {
                    form.hidden_voi_1 = hiddenValue;
                }
            }

            if (voi2) {
                const hiddenValue = this.getHiddenVoiIdFromKeyword(voi2);

                if (hiddenValue) {
                    form.hidden_voi_2 = hiddenValue;
                }
            }

            if (voi3) {
                const hiddenValue = this.getHiddenVoiIdFromKeyword(voi3);

                if (hiddenValue) {
                    form.hidden_voi_3 = hiddenValue;
                }
            }

            const date = new Date();

            const req: ICreateConsumerRequest = {
                consumers: [
                    {
                        eventDayId: this.eventDayId.value,
                        rElatId: ExternalDataReportingRelatId,
                        interactions: [
                            {
                                createDtm: this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss", "UTC") || "",
                                answers: {
                                    ...form
                                }
                            }
                        ]
                    }
                ]
            };

            this.eshotsService
                .createConsumer(req)
                .pipe(
                    catchError((err) => {
                        this.loadingService.setFinishedLoading();
                        this.errorModalShowing = true;
                        throw Error("Error submitting registration data");
                    })
                )
                .subscribe((res) => {
                    const eventName = this.event.value.name || "";

                    this.sessionService.setSessionData({
                        email: form.email,
                        eventName
                    });

                    this.loadingService.setFinishedLoading();

                    this.router.navigate(["/", "how-it-works"]);
                });
        } catch (e) {
            this.loadingService.setFinishedLoading();

            this.errorModalShowing = true;
        }
    }

    private getFlattenedFormData(form: FormGroup): IRegistrationFormData | null {
        if (!form.valid) {
            return null;
        }

        try {
            let flattenedForm: IRegistrationFormData = {
                ...this.ageAndCountryFormGroup.value,
                email: this.email.value,
                ...this.userInfoFormGroup.value,
                ...this.voiFormGroup.value,
                ...this.buyerReadinessFormGroup.value,
                contact: this.contact.value || "no"
            };

            if (this.contact.value === "yes") {
                flattenedForm = { ...flattenedForm, ...this.contactTypeSelection.value };
            }
            return flattenedForm;
        } catch {
            console.error("Unable to parse form data for submission");
            return null;
        }
    }

    private initRegistrationQuestionnaireForm(): FormGroup {
        return this.formBuilder.group({
            event: this.formBuilder.group({
                event: new FormControl<IEvent | null>(null),
                eventDayId: new FormControl<number>(0, [Validators.required])
            }),
            ageAndCountry: this.formBuilder.group({
                age_range: new FormControl<string>("", [Validators.required]),
                country: new FormControl<string>("", [Validators.required])
            }),
            email: new FormControl<string>("", [Validators.required, CustomValidators.email]),
            userInfo: this.formBuilder.group({
                first_name: new FormControl<string>("", [
                    Validators.required,
                    Validators.pattern(`^[a-zA-Z'\\.,\\- ]+$`)
                ]),
                last_name: new FormControl<string>("", [
                    Validators.required,
                    Validators.pattern(`^[a-zA-Z'\\.,\\- ]+$`)
                ]),
                street: new FormControl<string>("", [Validators.maxLength(255)]),
                zip_code: new FormControl<string>("", [
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(5),
                    Validators.pattern(`^\\d{5}$`)
                ]),
                city: new FormControl<string>("", [Validators.maxLength(255)]),
                state: new FormControl<string>("", [Validators.maxLength(2), Validators.minLength(2)])
            }),
            voiGroup: this.formBuilder.group({
                voi: new FormControl<string[]>([], [Validators.maxLength(3)])
            }),
            buyerReadiness: this.formBuilder.group({
                current_owner: new FormControl<string>("", [Validators.required]),
                purchase: new FormControl<string>("", [Validators.required]),
                opt_in: new FormControl<string>("", [Validators.required]),
                next_vehicle: new FormControl<string>("", [Validators.required])
            }),
            contact: new FormControl<string>("", [Validators.required]),
            contactTypeSelection: this.formBuilder.group({
                contact_options: new FormControl<string>(""),
                phone: new FormControl<string>("", [CustomValidators.phoneNumber])
            })
        });
    }

    private initFormListeners(): void {
        this.contact.valueChanges.pipe(untilDestroyed(this)).subscribe((contact) => {
            // If the user opts into contact, require the 'contact_option' field.
            if (contact === "yes") {
                this.contactOptions.setValidators([Validators.required]);
            } else {
                this.contactOptions.setValidators([]);
            }

            this.contactOptions.updateValueAndValidity();
            this.registrationQuestionnaireForm.updateValueAndValidity();
        });

        this.contactOptions.valueChanges.pipe(untilDestroyed(this)).subscribe((option) => {
            // If the user selects 'telephone' as their preferred contact option, require the 'phone' field.
            if (option === "telephone") {
                this.phone.setValidators([CustomValidators.phoneNumber, Validators.required]);
            } else {
                this.phone.setValidators([CustomValidators.phoneNumber]);
            }

            this.phone.updateValueAndValidity();
            this.registrationQuestionnaireForm.updateValueAndValidity();
        });

        this.phone.valueChanges.pipe(untilDestroyed(this)).subscribe((phoneNumber) => {
            let formattedPhoneNumber = "";

            if (phoneNumber) {
                formattedPhoneNumber = this.formatPhoneNumber(phoneNumber);

                if (formattedPhoneNumber != phoneNumber) {
                    this.phone.patchValue(formattedPhoneNumber);
                }
            }
        });
    }

    private formatPhoneNumber(phoneNumber: string): string {
        phoneNumber = phoneNumber.replace(/\D/g, "");
        const phoneLength = phoneNumber.length;

        if (phoneLength > 6) {
            return phoneNumber.slice(0, 3) + "-" + phoneNumber.slice(3, 6) + "-" + phoneNumber.slice(6);
        } else if (phoneLength > 3) {
            return phoneNumber.slice(0, 3) + "-" + phoneNumber.slice(3);
        }

        return phoneNumber;
    }

    public voiOptionSelected(keyword: string): void {
        let keywords: string[] = this.voi.value;
        keywords = keywords.includes(keyword) ? keywords.filter((k) => k !== keyword) : [...keywords, keyword];
        this.voi.setValue(keywords);
    }

    private getHiddenVoiIdFromKeyword(keyword: string): string | undefined {
        const voi: IRegistrationQuestionnaireVOIAnswer | undefined = this.answers.voiAnswers.find((voiAnswer) => {
            return voiAnswer.keyword === keyword;
        });

        if (voi) {
            return voi.hiddenId;
        }

        return undefined;
    }

    public formNext(): void {
        const swiperRef = this.swiperSlider?.swiperRef;

        if (swiperRef) {
            this.analyticsService.trackInteraction("FORM_NEXT");
            swiperRef.slideNext();
        }
    }

    public eventSelected(event: IEvent): void {
        const dateKey = new Date().toISOString().substring(0, 10).split("-").join("");
        const eventDayId: number | null = Number(event.eventDays[dateKey]) || null;
        this.event.setValue(event);
        this.eventDayId.setValue(eventDayId);
    }

    public ageRangeSelected(keyword: string): void {
        if (this.ageRange) {
            this.ageRange.setValue(keyword);
        }
    }

    public countrySelected(keyword: string): void {
        if (this.country) {
            this.country.setValue(keyword);
        }
    }

    public stateSelected(keyword: string): void {
        if (this.state) {
            this.state.setValue(keyword);
        }
    }

    public handleAgeAndCountrySubmission(formGroup: FormGroup): void {
        if (formGroup.valid && this.ageRange.value !== "under_18" && this.country.value !== "other") {
            this.formNext();
        } else {
            this.analyticsService.trackInteraction("FORM_NEXT");
            this.router.navigate(["/", "ineligible"]);
        }
    }

    public handleBuyerReadinessAndOptIn(): void {
        // Check if we should show contact us prompt, otherwise end here
        if (this.purchase.value === "0_3_months" || this.purchase.value === "4_6_months") {
            this.formNext();
        } else if (this.optIn.valid) {
            this.contact.setValue("no");
            this.submit();
        }
    }

    public handleContactSelection(): void {
        // Only show final slide if the user is interested in being contacted
        if (this.contact.value === "yes") {
            this.formNext();
        } else {
            // End here
            this.submit();
        }
    }

    public trackPrivacyPolicyClick(): void {
        this.analyticsService.trackInteraction("FORM_PRIVACY_POLICY");
    }

    get eventFormGroup(): FormGroup {
        return this.registrationQuestionnaireForm.get("event") as FormGroup;
    }

    get event(): FormControl {
        return this.eventFormGroup.get("event") as FormControl;
    }

    get eventDayId(): FormControl {
        return this.eventFormGroup.get("eventDayId") as FormControl;
    }

    get ageAndCountryFormGroup(): FormGroup {
        return this.registrationQuestionnaireForm.get("ageAndCountry") as FormGroup;
    }

    get emailFormGroup(): FormGroup {
        return this.registrationQuestionnaireForm.get("emailGroup") as FormGroup;
    }

    get userInfoFormGroup(): FormGroup {
        return this.registrationQuestionnaireForm.get("userInfo") as FormGroup;
    }

    get voiFormGroup(): FormGroup {
        return this.registrationQuestionnaireForm.get("voiGroup") as FormGroup;
    }

    get buyerReadinessFormGroup(): FormGroup {
        return this.registrationQuestionnaireForm.get("buyerReadiness") as FormGroup;
    }

    get contactTypeSelection(): FormGroup {
        return this.registrationQuestionnaireForm.get("contactTypeSelection") as FormGroup;
    }

    get voi(): FormControl<string[]> {
        return this.voiFormGroup.get("voi") as FormControl<string[]>;
    }

    get ageRange(): FormControl<string> {
        return this.ageAndCountryFormGroup.get("age_range") as FormControl<string>;
    }

    get country(): FormControl<string> {
        return this.ageAndCountryFormGroup.get("country") as FormControl<string>;
    }

    get state(): FormControl<string> {
        return this.userInfoFormGroup.get("state") as FormControl<string>;
    }

    get email(): FormControl<string> {
        return this.registrationQuestionnaireForm.get("email") as FormControl<string>;
    }

    get purchase(): FormControl<string> {
        return this.buyerReadinessFormGroup.get("purchase") as FormControl<string>;
    }

    get optIn(): FormControl<string> {
        return this.buyerReadinessFormGroup.get("opt_in") as FormControl;
    }

    get contact(): FormControl<string> {
        return this.registrationQuestionnaireForm.get("contact") as FormControl<string>;
    }

    get contactOptions(): FormControl<string> {
        return this.contactTypeSelection.get("contact_options") as FormControl<string>;
    }

    get phone(): FormControl<string> {
        return this.contactTypeSelection.get("phone") as FormControl<string>;
    }
}
