<header class="header">
    <div class="logo">
        <img class="logo__img" src="assets/images/logos/logo-toyota-passport.svg" alt="Toyota Passport Challenge Logo" />
    </div>
    <ng-container *ngIf="showHamburger">
        <button class="icon-button" (click)="openNav()">
            <img src="assets/icons/icon-nav-toggle.svg" alt="Open Nav Button">
        </button>
    </ng-container>
    <ng-container *ngIf="showX">
        <app-svg-button
            class="icon-button--close" 
            fileName="icon-close.svg" 
            customClass="icon-button--close"
            (buttonClick)="closeRules()"
            color="black"
        >
        </app-svg-button>
    </ng-container >
    

    <app-nav *ngIf="showNav" (closeMenu)="closeNav()" [@fadeIn]></app-nav>
</header>
