import { Pipe, PipeTransform } from "@angular/core";
import { VoiType } from "src/app/common/enums/VoiType.enum";
import {
    IRegistrationQuestionnaireAnswer,
    IRegistrationQuestionnaireVOIAnswer
} from "src/app/common/models/RegistrationQuestionnaire.model";

@Pipe({
    name: "voiType"
})
export class VoiTypePipe implements PipeTransform {
    transform(vois: IRegistrationQuestionnaireVOIAnswer[], type: VoiType): IRegistrationQuestionnaireAnswer[] {
        return vois.filter((voi) => voi.type === type);
    }
}
