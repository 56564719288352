import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-select",
    templateUrl: "./select.component.html",
    styleUrls: ["./select.component.scss"]
})
export class SelectComponent implements OnInit {
    @Input() label: string = "";
    @Input() hasError: boolean = false;

    public isExpanded: boolean = false;

    constructor() {}

    ngOnInit(): void {}

    public toggleAccordion(): void {
        this.isExpanded = !this.isExpanded;
    }

    public close(): void {
        this.isExpanded = false;
    }
}
