import { IRegistrationFormData } from "./RegistrationQuestionnaire.model";

export const ExternalDataReportingRelatId: number = 50006085;

export interface IConsumerResponse {
    consumer: {
        answers: Partial<IRegistrationFormData>;
        events?: IUserAttendedEvents;
    };
}

export interface ICreateConsumerRequest {
    consumers: [
        {
            eventDayId: number;
            rElatId: number;
            interactions: [
                {
                    createDtm: string; // Must be in UTC timezone
                    answers: IRegistrationFormData;
                }
            ];
        }
    ];
}

export interface IUpdateConsumerRequest {
    consumers: [
        {
            eventDayId?: number;
            rElatId: number;
            interactions: [
                {
                    createDtm: string; // Must be in UTC timezone
                    answers: Partial<IRegistrationFormData>;
                }
            ];
        }
    ];
}

export interface IUserAttendedEvents {
    [eventId: string]: {
        eventDays: IUserAttendedEventDays;
        name: string;
    };
}

export interface IUserAttendedEventDays {
    [eventDayId: string]: string;
}

export interface ICreateConsumerResponse {
    message: string;
}

export interface IEventDaysResponse {
    events: IEvent[];
}

export interface IEvent {
    eventDays: {
        [id: string]: string;
    };
    eventId: number;
    name: string;
}

export interface IRelatsResponse {
    clientLicenseId: number;
    name: string;
    relats: IRelat[];
}

export interface IRelat {
    activity: RelatActivity;
    eventLocation: string;
    rElatId: number;
}

export enum RelatActivity {
    ExternalDataReporting = "External Data Reporting"
}

// Field should either be 'yes' if the quiz is completed, or left off of the request entirely.
export interface IQuizStatusRequest {
    sweepstake_complete?: "yes";
    mobility_olympic_paralympic?: "yes";
    motorsports?: "yes";
    americanization?: "yes";
    electrification?: "yes";
}
