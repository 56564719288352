export const imageAssets: string[] = [
    "assets/images/complete/car.jpg",
    "assets/images/dashboard/dashboard-hero.png",
    "assets/images/dashboard/electrified.png",
    "assets/images/dashboard/team-toyota.png",
    "assets/images/dashboard/toyota-in-america.png",
    "assets/images/dashboard/toyota-racing.png",
    "assets/images/form-choices/4runner.png",
    "assets/images/form-choices/2023_crown.png",
    "assets/images/form-choices/2023_gr_corolla.png",
    "assets/images/form-choices/2023_gr_supra.png",
    "assets/images/form-choices/2023_tacoma.png",
    "assets/images/form-choices/bz4x.png",
    "assets/images/form-choices/c_hr.png",
    "assets/images/form-choices/camry_hybrid.png",
    "assets/images/form-choices/camry.png",
    "assets/images/form-choices/corolla_cross_hybrid.png",
    "assets/images/form-choices/corolla_cross.png",
    "assets/images/form-choices/corolla_hatchback.png",
    "assets/images/form-choices/corolla_hybrid.png",
    "assets/images/form-choices/corolla.png",
    "assets/images/form-choices/gr86.png",
    "assets/images/form-choices/grand_highlander.png",
    "assets/images/form-choices/highlander_hybrid.png",
    "assets/images/form-choices/highlander.png",
    "assets/images/form-choices/mirai.png",
    "assets/images/form-choices/2023_prius_prime.png",
    "assets/images/form-choices/prius.png",
    "assets/images/form-choices/rav4_hybrid.png",
    "assets/images/form-choices/rav4_prime.png",
    "assets/images/form-choices/rav4.png",
    "assets/images/form-choices/sequoia.png",
    "assets/images/form-choices/sienna.png",
    "assets/images/form-choices/toyota_mobility.png",
    "assets/images/form-choices/tundra_i_force_max.png",
    "assets/images/form-choices/tundra.png",
    "assets/images/form-choices/venza.png",
    "assets/images/home/car-1.jpg",
    "assets/images/home/car-2.jpg",
    "assets/images/home/car-3.jpg",
    "assets/images/quiz/answers/electrified-1.jpg",
    "assets/images/quiz/answers/toyota-in-america-1.jpg",
    "assets/images/quiz/question-heroes/electrified-1.jpg",
    "assets/images/quiz/question-heroes/team-toyota-1.jpg",
    "assets/images/quiz/question-heroes/team-toyota-2.jpg",
    "assets/images/quiz/question-heroes/team-toyota-3.jpg",
    "assets/images/quiz/question-heroes/toyota-in-america-1.jpg",
    "assets/images/quiz/question-heroes/toyota-racing-1.jpg",
    "assets/images/quiz/question-heroes/toyota-racing-2.jpg",
    "assets/images/quiz/question-heroes/toyota-racing-3.jpg",
    "assets/images/quiz-landing/pillars/electrified.png",
    "assets/images/quiz-landing/pillars/team-toyota.png",
    "assets/images/quiz-landing/pillars/toyota-in-america.png",
    "assets/images/quiz-landing/pillars/toyota-racing.png",
    "assets/images/quiz-landing/shape-heroes/electrified.png",
    "assets/images/quiz-landing/shape-heroes/team-toyota.png",
    "assets/images/quiz-landing/shape-heroes/toyota-in-america.png",
    "assets/images/quiz-landing/shape-heroes/toyota-racing.png",
    "assets/images/registration/toyota-registration-bg.png",
    "assets/images/how-it-works-hero.png",
    "assets/images/placeholder.png"
];

export const videoAssets: string[] = [];
