import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Pillar } from "src/app/common/models/Pillar.interface";
import { PillarService } from "src/app/shared/services/pillar.service";
import { ProgressService } from "src/app/shared/services/progress.service";
import { StripOutTagsPipe } from "../../pipes/strip-out-tags.pipe";
import { AnalyticsService } from "../../services/analytics.service";

@UntilDestroy()
@Component({
    selector: "app-nav",
    templateUrl: "./nav.component.html",
    styleUrls: ["./nav.component.scss"],
    providers: [StripOutTagsPipe]
})
export class NavComponent implements OnInit {
    @Output() public closeMenu: EventEmitter<void> = new EventEmitter<void>();

    public activeLink: string = "";

    public pillars: Pillar[] = [];
    public activePillarId: string = "";

    public completedQuizIds: string[] = [];

    constructor(
        private pillarService: PillarService,
        private progressService: ProgressService,
        private analyticsService: AnalyticsService,
        private stripOutTagsPipe: StripOutTagsPipe
    ) {}

    async ngOnInit(): Promise<void> {
        this.pillars = await this.pillarService.getAllPillars();

        this.pillarService
            .getCurrentPillarId()
            .pipe(untilDestroyed(this))
            .subscribe((id) => {
                this.activePillarId = id || "";
            });

        this.progressService
            .getCompletedQuizIds()
            .pipe(untilDestroyed(this))
            .subscribe((completedQuizIds) => {
                this.completedQuizIds = completedQuizIds;
            });
    }

    public close(clearPillarId?: boolean): void {
        if (clearPillarId) {
            this.activePillarId = "";
        }

        this.closeMenu.emit();
    }

    public goToPillar(pillar?: Pillar): void {
        if (pillar) {
            const formattedName = this.stripOutTagsPipe.transform(pillar.name);

            this.analyticsService.trackCustomEvent("Go To Pillar", {
                event_category: "Nav Menu",
                event_label: `Go To ${formattedName}`
            });

            this.pillarService.setCurrentPillarId(pillar.id);
        } else {
            this.pillarService.setCurrentPillarId("");
        }
    }

    public handleLinkClick(interactionId: string): void {
        this.analyticsService.trackInteraction(interactionId);
        this.close(true);
    }

    public handleCloseButtonClick(): void {
        this.analyticsService.trackInteraction("NAV_MENU_CLOSE");

        this.close();
    }

    public openOfficialRules(): void {
        this.analyticsService.trackInteraction("NAV_MENU_OPEN_RULES");
        this.close();
    }
}
