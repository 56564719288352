import { Injectable } from "@angular/core";
import { Answer, Choice, Question, QuestionProgress, SelectionMap } from "src/app/common/models/Quiz.interface";
import { QuizLoaderService } from "./quiz-loader.service";

@Injectable({
    providedIn: "root"
})
export class QuestionService {
    constructor(private quizLoader: QuizLoaderService) {}

    public async getQuestion(quizId: string, questionId: string): Promise<Question | undefined> {
        const quiz = await this.quizLoader.getQuizById(quizId);

        if (!quiz || !quiz.questions.length) {
            return undefined;
        }

        return quiz.questions.find((question) => {
            return question.id === questionId;
        });
    }

    public async getValidAnswers(quizId: string, questionId: string): Promise<string[]> {
        const question = await this.getQuestion(quizId, questionId);

        if (question) {
            return question.answers.map((answer: Answer) => answer.id);
        } else {
            return [];
        }
    }

    public async getValidChoices(quizId: string, questionId: string): Promise<string[]> {
        const question = await this.getQuestion(quizId, questionId);

        if (question) {
            return question.choices.map((choices) => {
                return choices.id;
            });
        } else {
            return [];
        }
    }

    public async getAnswerLabels(quizId: string, questionId: string): Promise<string[]> {
        const question = await this.getQuestion(quizId, questionId);

        if (question) {
            const answerIds = question.answers.map((answer) => {
                return answer.id;
            });
            return question.choices
                .filter((choice: Choice) => {
                    return answerIds.includes(choice.id);
                })
                .map((choice: Choice) => {
                    return choice.text;
                });
        }

        return [];
    }

    public async hasAnswerPage(quizId: string, questionId: string): Promise<boolean> {
        const question = await this.getQuestion(quizId, questionId);

        if (question?.answerPage) {
            return true;
        }

        return false;
    }

    public async isValidQuestionProgress(quizId: string, questionProgress: QuestionProgress): Promise<boolean> {
        if (!quizId || !questionProgress) {
            return false;
        }

        if (!("questionId" in questionProgress)) {
            return false;
        }

        if (typeof questionProgress.questionId !== "string") {
            return false;
        }

        if (!("selections" in questionProgress)) {
            return false;
        }

        const entries = Object.entries(questionProgress.selections);
        if (!entries.length) {
            return false;
        }

        const validSelectionTypes = entries.every((selectionEntry) => {
            const [key, value] = selectionEntry;
            if (typeof key !== "string") {
                return false;
            }

            if (typeof value !== "boolean") {
                return false;
            }

            return true;
        });

        if (!validSelectionTypes) {
            return false;
        }

        return await this.checkIfChoicesAreValid(quizId, questionProgress);
    }

    public async checkIfChoicesAreValid(quizId: string, questionProgress: QuestionProgress): Promise<boolean> {
        const validChoices = await this.getValidChoices(quizId, questionProgress.questionId);

        if (validChoices.length) {
            const responseIds = this.getResponseIdsFromSelections(questionProgress.selections);

            return responseIds.every((responseId) => {
                return validChoices.includes(responseId);
            });
        }

        return false;
    }

    public async checkIfResponseIsCorrect(quizId: string, questionProgress: QuestionProgress): Promise<boolean> {
        const validAnswers = await this.getValidAnswers(quizId, questionProgress.questionId);
        if (validAnswers.length) {
            const responseIds = this.getResponseIdsFromSelections(questionProgress.selections);
            return validAnswers.every((answer) => {
                return responseIds.includes(answer);
            });
        }

        return false;
    }

    private getResponseIdsFromSelections(selections: SelectionMap): string[] {
        return Object.entries(selections)
            .filter((pair) => {
                return pair[1];
            })
            .map((entry) => entry[0]);
    }
}
