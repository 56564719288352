import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-ineligible",
    templateUrl: "./ineligible.component.html",
    styleUrls: ["./ineligible.component.scss"]
})
export class IneligibleComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit(): void {}

    public goHome(): void {
        this.router.navigate(["/"]);
    }
}
