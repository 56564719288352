import { VoiType } from "../enums/VoiType.enum";

export interface IRegistrationFormData {
    age_range: string;
    country: string;
    email: string;
    first_name: string;
    zip_code: string;
    last_name: string;
    street: string;
    city: string;
    text: string;
    voi: string[];
    hidden_voi_1?: string;
    hidden_voi_2?: string;
    hidden_voi_3?: string;
    current_owner: string;
    purchase: string;
    next_vehicle: string;
    opt_in: string;
    hidden_opt_in?: string;
    contact: string;
    contact_options: string;
    phone: string;
    sweepstake_complete?: string;
    mobility_olympic_paralympic?: string;
    motorsports?: string;
    americanization?: string;
    electrification?: string;
}

export interface IRegistrationQuestionnaireAnswer {
    keyword: string;
    text: string;
}

export interface IRegistrationQuestionnaireVOIAnswer extends IRegistrationQuestionnaireAnswer {
    type: VoiType;
    imageUrl?: string;
    hiddenId: string;
}

export class RegistrationQuestionnaireAnswers {
    ageRangeAnswers: IRegistrationQuestionnaireAnswer[] = [
        {
            keyword: "under_18",
            text: "Under 18"
        },
        {
            keyword: "18_25",
            text: "18-25"
        },
        {
            keyword: "26_35",
            text: "26-35"
        },
        {
            keyword: "36_45",
            text: "36-45"
        },
        {
            keyword: "46_55",
            text: "46-55"
        },
        {
            keyword: "56_65",
            text: "56-65"
        },
        {
            keyword: "over_65",
            text: "Over 65"
        }
    ];

    countryAnswers: IRegistrationQuestionnaireAnswer[] = [
        {
            keyword: "usa",
            text: "USA"
        },
        {
            keyword: "other",
            text: "Other"
        }
    ];

    stateAnswers: IRegistrationQuestionnaireAnswer[] = [
        {
            keyword: "AL",
            text: "Alabama"
        },
        {
            keyword: "AK",
            text: "Alaska"
        },
        {
            keyword: "AZ",
            text: "Arizona"
        },
        {
            keyword: "AR",
            text: "Arkansas"
        },
        {
            keyword: "CA",
            text: "California"
        },
        {
            keyword: "CO",
            text: "Colorado"
        },
        {
            keyword: "CT",
            text: "Connecticut"
        },
        {
            keyword: "DE",
            text: "Delaware"
        },
        {
            keyword: "DC",
            text: "District of Columbia"
        },
        {
            keyword: "FL",
            text: "Florida"
        },
        {
            keyword: "GA",
            text: "Georgia"
        },
        {
            keyword: "GU",
            text: "Guam"
        },
        {
            keyword: "HI",
            text: "Hawaii"
        },
        {
            keyword: "ID",
            text: "Idaho"
        },
        {
            keyword: "IL",
            text: "Illinois"
        },
        {
            keyword: "IN",
            text: "Indiana"
        },
        {
            keyword: "IA",
            text: "Iowa"
        },
        {
            keyword: "KS",
            text: "Kansas"
        },
        {
            keyword: "KY",
            text: "Kentucky"
        },
        {
            keyword: "LA",
            text: "Louisiana"
        },
        {
            keyword: "ME",
            text: "Maine"
        },
        {
            keyword: "MH",
            text: "Marshall Islands"
        },
        {
            keyword: "MD",
            text: "Maryland"
        },
        {
            keyword: "MA",
            text: "Massachusetts"
        },
        {
            keyword: "MI",
            text: "Michigan"
        },
        {
            keyword: "MN",
            text: "Minnesota"
        },
        {
            keyword: "MS",
            text: "Mississippi"
        },
        {
            keyword: "MO",
            text: "Missouri"
        },
        {
            keyword: "MT",
            text: "Montana"
        },
        {
            keyword: "NE",
            text: "Nebraska"
        },
        {
            keyword: "NV",
            text: "Nevada"
        },
        {
            keyword: "NH",
            text: "New Hampshire"
        },
        {
            keyword: "NJ",
            text: "New Jersey"
        },
        {
            keyword: "NM",
            text: "New Mexico"
        },
        {
            keyword: "MP",
            text: "Northern Mariana Island"
        },
        {
            keyword: "NY",
            text: "New York"
        },
        {
            keyword: "NC",
            text: "North Carolina"
        },
        {
            keyword: "ND",
            text: "North Dakota"
        },
        {
            keyword: "OH",
            text: "Ohio"
        },
        {
            keyword: "OK",
            text: "Oklahoma"
        },
        {
            keyword: "OR",
            text: "Oregon"
        },
        {
            keyword: "PA",
            text: "Pennsylvania"
        },
        {
            keyword: "PR",
            text: "Puerto Rico"
        },
        {
            keyword: "RI",
            text: "Rhode Island"
        },
        {
            keyword: "SC",
            text: "South Carolina"
        },
        {
            keyword: "SD",
            text: "South Dakota"
        },
        {
            keyword: "TN",
            text: "Tennessee"
        },
        {
            keyword: "TX",
            text: "Texas"
        },
        {
            keyword: "UT",
            text: "Utah"
        },
        {
            keyword: "VT",
            text: "Vermont"
        },
        {
            keyword: "VI",
            text: "Virgin Islands"
        },
        {
            keyword: "VA",
            text: "Virginia"
        },
        {
            keyword: "WA",
            text: "Washington"
        },
        {
            keyword: "WV",
            text: "West Virginia"
        },
        {
            keyword: "WI",
            text: "Wisconsin"
        },
        {
            keyword: "WY",
            text: "Wyoming"
        }
    ];

    voiAnswers: IRegistrationQuestionnaireVOIAnswer[] = [
        {
            keyword: "4runner",
            text: "4Runner",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003652"
        },
        {
            keyword: "bz4x",
            text: "bZ4X",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003656"
        },
        {
            keyword: "camry",
            text: "Camry",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003657"
        },
        {
            keyword: "camry_hybrid",
            text: "Camry Hybrid",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003658"
        },
        {
            keyword: "c_hr",
            text: "C-HR",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003659"
        },
        {
            keyword: "corolla",
            text: "Corolla",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003660"
        },
        {
            keyword: "corolla_cross",
            text: "Corolla Cross",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003661"
        },
        {
            keyword: "corolla_cross_hybrid",
            text: "Corolla Cross Hybrid",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003662"
        },
        {
            keyword: "corolla_hatchback",
            text: "Corolla Hatchback",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003663"
        },
        {
            keyword: "corolla_hybrid",
            text: "Corolla Hybrid",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003664"
        },
        {
            keyword: "2023_crown",
            text: "2023 Crown",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003665"
        },
        {
            keyword: "2023_gr_corolla",
            text: "2023 GR Corolla",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003666"
        },
        {
            keyword: "gr86",
            text: "GR86",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003667"
        },
        {
            keyword: "2023_gr_supra",
            text: "2023 GR Supra",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003670"
        },
        {
            keyword: "grand_highlander",
            text: "Grand Highlander",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50004020"
        },
        {
            keyword: "highlander",
            text: "Highlander",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003671"
        },
        {
            keyword: "highlander_hybrid",
            text: "Highlander Hybrid",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003672"
        },
        {
            keyword: "mirai",
            text: "Mirai",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003673"
        },
        {
            keyword: "prius",
            text: "Prius",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003674"
        },
        {
            keyword: "2023_prius",
            text: "2023 Prius",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003853"
        },
        {
            keyword: "2023_prius_prime",
            text: "Prius Prime",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003654"
        },

        {
            keyword: "rav4",
            text: "RAV4",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003676"
        },
        {
            keyword: "rav4_hybrid",
            text: "RAV4 Hybrid",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003677"
        },
        {
            keyword: "rav4_prime",
            text: "RAV4 Prime",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003679"
        },
        {
            keyword: "sequoia",
            text: "sequoia",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003680"
        },
        {
            keyword: "sienna",
            text: "Sienna",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003681"
        },
        {
            keyword: "2023_trailhunter",
            text: "2023 Trailhunter",
            type: VoiType.Trucks,
            hiddenId: "50003855"
        },
        {
            keyword: "2023_tacoma",
            text: "Tacoma",
            type: VoiType.Trucks,
            hiddenId: "50003683"
        },
        {
            keyword: "tundra",
            text: "Tundra",
            type: VoiType.Trucks,
            hiddenId: "50003684"
        },
        {
            keyword: "tundra_i_force_max",
            text: "Tundra i-FORCE MAX",
            type: VoiType.Trucks,
            hiddenId: "50003685"
        },
        {
            keyword: "venza",
            text: "Venza",
            type: VoiType.CrossoversAndSUVs,
            hiddenId: "50003686"
        },
        {
            keyword: "toyota_mobility",
            text: "Toyota Mobility",
            type: VoiType.CarsAndMinivans,
            hiddenId: "50003688"
        }
    ];

    currentOwnerAnswers: IRegistrationQuestionnaireAnswer[] = [
        {
            keyword: "yes",
            text: "Yes"
        },
        {
            keyword: "no",
            text: "No"
        }
    ];

    purchaseAnswers: IRegistrationQuestionnaireAnswer[] = [
        {
            keyword: "0_3_months",
            text: "In 0-3 months"
        },
        {
            keyword: "4_6_months",
            text: "In 4-6 months"
        },
        {
            keyword: "7_12_months",
            text: "In 7-12 months"
        },
        {
            keyword: "12_plus_months",
            text: "In 12+ months"
        }
    ];

    nextVehicleAnswers: IRegistrationQuestionnaireAnswer[] = [
        {
            keyword: "buy_new",
            text: "Buy New"
        },
        {
            keyword: "buy_used",
            text: "Buy Used"
        },
        {
            keyword: "lease",
            text: "Lease"
        }
    ];

    optInAnswers: IRegistrationQuestionnaireAnswer[] = [
        {
            keyword: "yes",
            text: "Yes"
        },
        {
            keyword: "no",
            text: "No"
        }
    ];

    contactAnswers: IRegistrationQuestionnaireAnswer[] = [
        {
            keyword: "yes",
            text: "Yes"
        },
        {
            keyword: "no",
            text: "No"
        }
    ];

    contactOptionAnswers: IRegistrationQuestionnaireAnswer[] = [
        {
            keyword: "telephone",
            text: "Telephone"
        },
        {
            keyword: "email_address",
            text: "Email Address"
        }
    ];
}
