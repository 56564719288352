<div class="select form-group" [ngClass]="{'select--active': isExpanded, 'select--error': hasError}">
    <div class="select-toggle form-field" (click)="toggleAccordion()">
        <span class="">{{ label }}</span>
        <span 
            class="select-toggle__icon" 
            [ngClass]="{'select-toggle__icon--active': isExpanded}"
        >
        </span>
    </div>
    <div class="select__content">
        <div class="border-wrapper">
            <div class="scroll-wrapper">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>