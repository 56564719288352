<div class="page">
    <div class="content">
        <div class="logo">
            <img class="logo__img" src="assets/images/logos/logo-toyota-passport.svg" alt="Toyota Passport Challenge Logo" />
        </div>

        <p class="lead text--lg text--centered">Visit all 4 passport destinations throughout the auto show space to 
                enter for a chance to win a 2023 Toyota Crown.</p>
         <p class="text text--sm text--centered uppercase">PLUS PLATINUM VEHICLE SERVICE AGREEMENT FROM Toyota Financial Services FOR ONE FULL YEAR!</p>       
    </div>
    <img class="image image--first" src="assets/images/home/car-1.jpg" alt="Red car view from driver-side front">
    <div class="row">
        <img src="assets/images/home/car-2.jpg" alt="Red car view from passenger-side rear" class="image image--half-width"> 
        <img src="assets/images/home/car-3.jpg" alt="Red car close up of driver-side headlight" class="image image--half-width">
    </div>
    <div class="c-button">
        <app-button text="Start Your Journey" (buttonClick)="startJourney()"></app-button>
    </div>
    <div class="red-bar"></div>
    <div class="disclaimer">
        <p>NO PURCHASE NECESSARY TO ENTER OR WIN. Ends 5/20/23. Must be a permanent legal resident of the U.S. or DC (excluding HI, AK, PR), legal age of majority & at least 18 years of age. Void in HI, AK, PR & where prohibited. <a href="/rules">Click here</a> for Official Rules & complete details. Prize images are for illustrative purposes only. Actual prize may vary.</p>
    </div>
</div>
<div class="red-animation" [ngClass]="{ 'red-animation--active': redAnimationActive }"></div>
<div class="white-animation" [ngClass]="{ 'white-animation--active': whiteAnimationActive }"></div>
