import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "voiOptionSelected"
})
export class VoiOptionSelectedPipe implements PipeTransform {
    transform(keyword: string, selectedVoiKeywords: string[]): boolean {
        return selectedVoiKeywords.includes(keyword);
    }
}
