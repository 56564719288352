import { Component, OnInit } from "@angular/core";
import { AnalyticsService } from "src/app/shared/services/analytics.service";

@Component({
    selector: "app-complete",
    templateUrl: "./complete.component.html",
    styleUrls: ["./complete.component.scss"]
})
export class CompleteComponent implements OnInit {
    constructor(private analyticsService: AnalyticsService) {}

    ngOnInit(): void {}

    public handleButtonClick(): void {
        this.analyticsService.trackInteraction("EXPLORE_TOYOTA");
        window.open("https://toyota.com", "_blank");
    }
}
