<div class="page">
    <div *ngIf="!showModal; else checkModal" class="register-landing">

        <div class="register-landing__content">
            <h5 class="lead text--lg text--centered">Are you registered <br> with Toyota?</h5>
            <app-button text="I'm already registered" customClass="button--dark button--dark-border button--transparent" [resetAfterEvent]="true" (buttonClick)="showSignInForm()">I'm already registered</app-button>
            <app-button text="I'm not registered" customClass="button--dark button--dark-border button--transparent" (buttonClick)="goToRegistrationQuestionnaire('NOT_REGISTERED_BUTTON')">I'm already registered</app-button>
        </div>
    </div>

    <ng-template #checkModal>
        <div *ngIf="!form; else loginForm">
            <app-agree-to-share-info (btnClose)="hideCheckModal()" (agreeEvent)="receiveAgreeEvent($event)" (btnNext)="agreeToShareInfo()"></app-agree-to-share-info>
        </div>
    </ng-template>
    
    
    <ng-template #loginForm>
        
        <form class="form" [formGroup]="form">
            <h1 class="form__title">Sign In</h1>
            <div class="form__content form-content">
                <input class="form-field" type="email" formControlName="email" placeholder="Email Address*">
                <a class="form__link"(click)="goToRegistrationQuestionnaire('NOT_REGISTERED_SIGNIN_FORM')"> 
                    I'm not registered
                </a>
            </div>
            <p class="disclaimer disclaimer--email">By clicking ‘Next’ your information is being saved to be provided to Toyota for sales and marketing purposes in accordance with its <a href="http://www.toyota.com/support/privacy-rights" target="_blank">Privacy Policy</a>.</p>
            <div [ngClass]="{'expanding-footer-button--show': form.valid}" class="expanding-footer-button">
                <app-button text="Next" (buttonClick)="checkRegistrationStatus()" [disabled]="!form.valid" [resetAfterEvent]="true"></app-button>
            </div>
        </form>
    </ng-template>
</div>

<app-modal *ngIf="errorModalShowing" iconFileName="icon-retry.svg" title="You're Not registered" (modalClosed)="hideErrorModal()">
    <p>We can't find your email. Please register with Toyota!</p>
</app-modal>