<div class="page">
    <div *ngIf="eventsLoaded && showModal && !errorModalShowing && !noEventsModalShowing">
        <app-agree-to-share-info (btnClose)="hideCheckModal()" (agreeEvent)="receiveAgreeEvent($event)" (btnNext)="agreeToShareInfo()"></app-agree-to-share-info>
    </div>

    <ng-container *ngIf="eventsLoaded && !showModal" #form>
       <swiper *ngIf="registrationQuestionnaireForm" #swiperSlider class="form-slider" [autoHeight]="true" [allowTouchMove]="false">
        <!-- Event Selection -->
        <ng-template swiperSlide>
            <div class="slide">
                <div class="inner-wrapper" [formGroup]="eventFormGroup">
                    <div class="slide__content">
                        <h3 class="form__header text--lg text--centered">Fill out the below information.</h3>
                        <app-select #eventDropdown [label]="event.value?.name || 'Choose your event location'">
                            <ul class="select-options">
                                <li *ngFor="let e of events" class="select-option"
                                    [ngClass]="{ 'select-option--active': event && event.value?.eventId === e.eventId}"
                                    (click)="eventSelected(e); eventDropdown.close()">
                                    {{ e.name }}
                                </li>
                            </ul>
                        </app-select>
                    </div>
                    <p class="disclaimer disclaimer--user-info">By clicking ‘Next’ your information is being saved to be provided to Toyota for sales and marketing purposes in accordance with its  <a href="http://www.toyota.com/support/privacy-rights" target="_blank" (click)="trackPrivacyPolicyClick()">Privacy policy</a></p>
                    <div class="expanding-footer-button" [ngClass]="{'expanding-footer-button--show': (event.value?.eventId && eventDayId.valid)}">
                        <app-button text="Next" (buttonClick)="formNext()" [disabled]="!(event.value?.eventId && eventDayId.valid)" [resetAfterEvent]="true"></app-button>
                    </div>
                </div>
            </div>
        </ng-template>
        <!--  Age and Country -->
        <ng-template swiperSlide>
            <div class="slide">
                <form class="inner-wrapper" [formGroup]="ageAndCountryFormGroup">
                    <div class="slide__content slide__content--age-country">
                        <h3 class="form__header text--lg text--centered">Fill out the below information.</h3>
                        <app-select #ageRangeDropdown
                            [label]="ageRange.value ?
                                        (ageRange.value | answerText: answers.ageRangeAnswers) : 'What is your age range?*'">
                            <ul class="select-options">
                                <li *ngFor="let answer of answers.ageRangeAnswers" class="select-option"
                                    [ngClass]="{ 'select-option--active': ageRange && ageRange.value === answer.keyword}"
                                    (click)="ageRangeSelected(answer.keyword); ageRangeDropdown.close()">
                                    {{ answer.text }}
                                </li>
                            </ul>
                        </app-select>
                        <app-select #countryDropdown
                            [label]="country.value ?
                                        (country.value | answerText: answers.countryAnswers) : 'Country of Residence*'">
                            <ul class="select-options">
                                <li *ngFor="let answer of answers.countryAnswers" class="select-option"
                                    [ngClass]="{ 'select-option--active': country && country.value === answer.keyword}"
                                    (click)="countrySelected(answer.keyword); countryDropdown.close()">
                                    {{ answer.text }}
                                </li>
                            </ul>
                        </app-select>
                    </div>
                    <div class="slide__footer">
                        <p class="disclaimer disclaimer--age-country">By clicking ‘Next’ your information is being saved to be provided to Toyota for sales and marketing purposes in accordance with its <a href="http://www.toyota.com/support/privacy-rights" target="_blank" (click)="trackPrivacyPolicyClick()">Privacy Policy</a>.</p>
                        <div [ngClass]="{'expanding-footer-button--show': (ageAndCountryFormGroup.valid)}" class="expanding-footer-button">
                            <app-button text="Next" (buttonClick)="handleAgeAndCountrySubmission(ageAndCountryFormGroup)" [disabled]="!(ageAndCountryFormGroup.valid)" [resetAfterEvent]="true"></app-button>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>
        <!-- Email -->
        <ng-template swiperSlide>
            <div class="slide">
                <form class="inner-wrapper" [formGroup]="registrationQuestionnaireForm">
                    <div class="slide__content">
                        <h3 class="form__header text--lg text--centered">Fill out the below information.</h3>
                        <input class="form-group form-field" type="email" formControlName="email" placeholder="Email*">
                    </div>
                    <div class="slide__footer">
                        <p class="disclaimer disclaimer--email">By clicking ‘Next’ your information is being saved to be provided to Toyota for sales and marketing purposes in accordance with its <a href="http://www.toyota.com/support/privacy-rights" target="_blank" (click)="trackPrivacyPolicyClick()">Privacy Policy</a>.</p>

                        <div class="expanding-footer-button"
                            [ngClass]="{'expanding-footer-button--show': (email.valid)}">
                            <app-button text="Next" (buttonClick)="checkRegistrationStatus()"
                                [disabled]="!(email.valid)" [resetAfterEvent]="true"></app-button>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>
        <!-- User Info  -->
        <ng-template swiperSlide>
            <div class="slide">
                <form class="inner-wrapper" [formGroup]="userInfoFormGroup">
                    <div class="slide__content slide__content--user-info">
                        <h3 class="form__header text--lg text--centered">Fill out the below information.</h3>
                        <input class="form-group form-field" type="text" formControlName="first_name"
                            placeholder="First Name*">
                        <input class="form-group form-field" type="text" formControlName="last_name"
                            placeholder="Last Name*">
                        <input class="form-group form-field" type="text" formControlName="street"
                            placeholder="Street (optional)">
                        <input class="form-group form-field" type="text" formControlName="zip_code" placeholder="Zip*">
                        <input class="form-group form-field" type="text" formControlName="city" placeholder="City">
                        <app-select #stateDropdown [label]="state.value ?
                                    (state.value | answerText: answers.stateAnswers) : 'State'">
                            <ul class="select-options">
                                <li *ngFor="let answer of answers.stateAnswers" class="select-option"
                                    [ngClass]="{ 'select-option--active': state && state.value === answer.keyword}"
                                    (click)="stateSelected(answer.keyword); stateDropdown.close()">
                                    {{ answer.text }}
                                </li>
                            </ul>
                        </app-select>
                    </div>
                    <div class="slide__footer">
                        <p class="disclaimer disclaimer--user-info">By clicking ‘Next’ your information is being saved to be provided to Toyota for sales and marketing purposes in accordance with its <a href="http://www.toyota.com/support/privacy-rights" target="_blank" (click)="trackPrivacyPolicyClick()">Privacy policy</a></p>

                        <div class="expanding-footer-button"
                            [ngClass]="{'expanding-footer-button--show': (userInfoFormGroup.valid)}">
                            <app-button text="Next" (buttonClick)="formNext()" [disabled]="!(userInfoFormGroup.valid)"
                                [resetAfterEvent]="true"></app-button>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>
        <!-- VOI selection -->
        <ng-template swiperSlide>
            <div class="slide">
                <form class="inner-wrapper" [formGroup]="voiFormGroup">
                    <div class="slide__content slide__content--voi">
                        <h3 class="form__header text--lg text--centered">Which Toyota vehicle are you most interested
                            in? Select up to 3 vehicles.</h3>
                        <ng-container *ngFor="let voiCategoryEnum of voiType | enumAsArray">
                            <app-select [label]="voiCategoryEnum.value">
                                <ul class="select-options select-options--grid">
                                    <ng-container
                                        *ngFor="let answer of answers.voiAnswers | voiType: voiCategoryEnum.value">
                                        <ng-container *ngTemplateOutlet="voiOption; context: { answer }"></ng-container>
                                    </ng-container>
                                </ul>
                            </app-select>
                        </ng-container>
                        <ng-template #voiOption let-answer="answer">
                            <app-image-choice [answer]="answer"
                                [selected]="answer.keyword | voiOptionSelected: voi.value"
                                [disabled]="answer.keyword | disableVoiOption: voi.value"
                                (choiceSelected)="voiOptionSelected(answer.keyword);">
                            </app-image-choice>
                        </ng-template>
                    </div>
                    <p class="disclaimer disclaimer--user-info">By clicking ‘Next’ your information is being saved to be provided to Toyota for sales and marketing purposes in accordance with its <a href="http://www.toyota.com/support/privacy-rights" target="_blank" (click)="trackPrivacyPolicyClick()">Privacy policy</a></p>
                    <div class="expanding-footer-button" [ngClass]="{'expanding-footer-button--show': (voiFormGroup.valid)}">
                        <app-button text="Next" (buttonClick)="formNext()" [disabled]="!(voiFormGroup.valid)" [resetAfterEvent]="true"></app-button>
                    </div>
                </form>
            </div>
        </ng-template>

        <!-- Buyer Readiness -->
        <ng-template swiperSlide>

            <div class="slide">
                <form class="inner-wrapper" [formGroup]="buyerReadinessFormGroup">
                    <div class="slide__content slide__content--buyer-readiness">
                        <h3 class="text--lg text--centered">Are you currently a Toyota owner?</h3>
                        <div class="radio-row">
                            <label class="radio-button" *ngFor="let answer of answers.currentOwnerAnswers">
                                <input type="radio" [value]="answer.keyword" formControlName="current_owner">
                                <span class="radio-button__indicator"></span>
                                <span class="radio-button__label">{{ answer.text }}</span>
                            </label>
                        </div>

                        <h3 class="text--lg text--centered radio-button-header">When do you plan to purchase or lease your next new vehicle?</h3>
                        <div class="c-radio-list">
                            <div class="radio-list">
                                <label *ngFor="let answer of answers.purchaseAnswers" class="radio-button">
                                    <input type="radio" [value]="answer.keyword" formControlName="purchase">
                                    <span class="radio-button__indicator"></span>
                                    <span class="radio-button__label">{{ answer.text }}</span>
                                </label>
                            </div>
                        </div>

                        <h3 class="text--lg text--centered radio-button-header">For your next vehicle, which is most likely?</h3>
                        <div class="c-radio-list">
                            <div class="radio-list">
                                <label *ngFor="let answer of answers.nextVehicleAnswers" class="radio-button">
                                    <input type="radio" [value]="answer.keyword" formControlName="next_vehicle">
                                    <span class="radio-button__indicator"></span>
                                    <span class="radio-button__label">{{ answer.text }}</span>
                                </label>
                            </div>
                        </div>

                        <!-- Opt In Request -->
                        <h3 class="text--lg text--centered radio-button-header">By checking Yes, I agree to receive exciting product and event news from Toyota via email.</h3>

                        <div class="radio-row">
                            <label *ngFor="let answer of answers.optInAnswers" class="radio-button">
                                <input type="radio" [value]="answer.keyword" formControlName="opt_in">
                                <span class="radio-button__indicator"></span>
                                <span class="radio-button__label">{{ answer.text }}</span>
                            </label>
                        </div>
                        
                    </div>
                    <p class="disclaimer disclaimer--user-info">By clicking ‘Next’ your information is being saved to be provided to Toyota for sales and marketing purposes in accordance with its <a href="http://www.toyota.com/support/privacy-rights" target="_blank" (click)="trackPrivacyPolicyClick()">Privacy policy</a></p>
                    <div class="expanding-footer-button"
                    
                        [ngClass]="{'expanding-footer-button--show': (buyerReadinessFormGroup.valid)}">
                        <app-button text="Next" (buttonClick)="handleBuyerReadinessAndOptIn()" [disabled]="!(buyerReadinessFormGroup.valid)"
                            [resetAfterEvent]="true"></app-button>
                    </div>
                </form>
            </div>
        </ng-template>

        <ng-template *ngIf="purchase.value === '0_3_months' || '4_6_months'" swiperSlide>
            <div class="slide">
                <form class="inner-wrapper" [formGroup]="registrationQuestionnaireForm">
                    <div class="slide__content">
                        <h3 class="text--lg text--centered">By checking Yes, I agree to share the information provided with a dealer in my area to be used for sales and marketing purposes.</h3>
                        <div class="radio-row">
                            <label *ngFor="let answer of answers.contactAnswers" class="radio-button">
                                <input type="radio" [value]="answer.keyword" formControlName="contact">
                                <span class="radio-button__indicator"></span>
                                <span class="radio-button__label">{{ answer.text }}</span>
                            </label>
                        </div>
                    </div>
                    <p class="disclaimer disclaimer--user-info">By clicking ‘Next’ your information is being saved to be provided to Toyota for sales and marketing purposes in accordance with its  <a href="http://www.toyota.com/support/privacy-rights" target="_blank" (click)="trackPrivacyPolicyClick()">Privacy policy</a></p>
                    <div class="expanding-footer-button" [ngClass]="{'expanding-footer-button--show': (contact.valid)}">
                        <app-button text="Next" (buttonClick)="handleContactSelection()" [disabled]="!(contact.valid)"
                            [resetAfterEvent]="true"></app-button>
                    </div>
                </form>
            </div>
        </ng-template>

        <ng-template *ngIf="contact.value === 'yes'" swiperSlide>
            <div class="slide">
                <form class="inner-wrapper" [formGroup]="contactTypeSelection">
                    <div class="slide__content slide__content--contact-type">
                        <h3 class="text--lg text--centered">How should a dealership sales person contact you?</h3>
                        <div class="c-radio-list">
                            <div class="radio-list">
                                <label *ngFor="let answer of answers.contactOptionAnswers" class="radio-button">
                                    <input type="radio" [value]="answer.keyword" formControlName="contact_options">
                                    <span class="radio-button__indicator"></span>
                                    <span class="radio-button__label">{{ answer.text }}</span>
                                </label>
                            </div>
                        </div>

                        <ng-container *ngIf="contactOptions.value === 'telephone'">
                            <h3 class="phone-label text--lg text--centered">Please provide your phone number</h3>
                            <input class="form-group form-field" type="text" formControlName="phone"
                                placeholder="Phone Number*">
                        </ng-container>
                    </div>
                    <p class="disclaimer disclaimer--user-info">By clicking ‘Next’ your information is being saved to be provided to Toyota for sales and marketing purposes in accordance with its  <a href="http://www.toyota.com/support/privacy-rights" target="_blank" (click)="trackPrivacyPolicyClick()">Privacy policy</a></p>
                    <div class="expanding-footer-button"
                        [ngClass]="{'expanding-footer-button--show': (contactTypeSelection.valid)}">
                        <app-button text="Next" (buttonClick)="submit()" [disabled]="!(contactTypeSelection.valid)"
                            [resetAfterEvent]="true"></app-button>
                    </div>
                </form>
            </div>
        </ng-template>

    </swiper> 
    </ng-container>
    
</div>

<app-modal *ngIf="noEventsModalShowing" (modalClosed)="closeNoEventsModal()">
    <p>Sorry. There are no active events for today. Please try again during an active event.</p>
</app-modal>

<app-modal *ngIf="errorModalShowing" (modalClosed)="closeErrorModal()">
    <p>Sorry something went wrong, please try again in a few seconds.</p>
</app-modal>

<app-modal *ngIf="alreadyRegisteredModalShowing" iconFileName="icon-checkmark.svg" title="You're Already Registered"
    (modalClosed)="goToSignIn()">
    <p>Your email is already registered with Toyota. Please sign in!</p>
</app-modal>