import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-official-rules",
    templateUrl: "./official-rules.component.html",
    styleUrls: ["./official-rules.component.scss"]
})
export class OfficialRulesComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
