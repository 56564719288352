import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { UserSession } from "src/app/common/models/UserSession.interface";
import { CustomValidators } from "src/app/validators/CustomValidators.validator";

@Injectable({
    providedIn: "root"
})
export class SessionService {
    private readonly SESSION_KEY: string = "tdp-session";

    private _userSession$: BehaviorSubject<UserSession | undefined> = new BehaviorSubject<UserSession | undefined>(
        undefined
    );

    constructor() {
        this.restoreUserSession();
    }

    public setSessionData(value: UserSession | undefined): void {
        if (!value) {
            return;
        }

        this.saveSession(value);
        this._userSession$.next(value);
    }

    public getSession(): Observable<UserSession | undefined> {
        return this._userSession$.asObservable();
    }

    public restoreUserSession(): void {
        const data: string | null = sessionStorage.getItem(this.SESSION_KEY);

        if (data) {
            try {
                const session = JSON.parse(data) || "";

                if (session) {
                    const email = session.email || "";
                    const eventName = session.eventName || "";

                    if (email) {
                        if (!CustomValidators.emailRegex.test(email)) {
                            throw Error();
                        }

                        if (eventName && typeof eventName !== "string") {
                            throw Error();
                        }

                        this._userSession$.next(session);
                    } else {
                        throw Error();
                    }
                } else {
                    throw Error();
                }
            } catch {
                this._userSession$.next(undefined);
                console.error("Unable to restore user session - data corrupted");
            }
        }
    }

    public saveSession(session: UserSession): void {
        try {
            if (session.email) {
                const formattedSession = JSON.stringify(session);
                sessionStorage.setItem(this.SESSION_KEY, formattedSession);
            }
        } catch {
            console.error("Unable to store session");
        }
    }

    public clearSession(): void {
        if (sessionStorage[this.SESSION_KEY]) {
            sessionStorage.removeItem(this.SESSION_KEY);
            this._userSession$.next(undefined);
        }
    }
}
