import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "stripOutTags"
})
export class StripOutTagsPipe implements PipeTransform {
    transform(value: string): string {
        return value.replace(/(<([^>]+)>)/gi, "");
    }
}
