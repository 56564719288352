import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, firstValueFrom, from, map, Observable, take } from "rxjs";
import { PassportProgress } from "src/app/common/models/Passport.interface";
import { QuestionProgressMap } from "src/app/common/models/Quiz.interface";
import { IRegistrationFormData } from "src/app/common/models/RegistrationQuestionnaire.model";
import { EshotsService } from "./eshots.service";
import { PillarService } from "./pillar.service";
import { QuestionService } from "./question.service";
import { QuizLoaderService } from "./quiz-loader.service";
import { SessionService } from "./session.service";

@UntilDestroy()
@Injectable({
    providedIn: "root"
})
export class ProgressService {
    private readonly SESSION_STORAGE_KEY = "tdp-prog";

    private _passportProgress$: BehaviorSubject<PassportProgress> = new BehaviorSubject<PassportProgress>({});

    constructor(
        private questionService: QuestionService,
        private quizLoader: QuizLoaderService,
        private pillarService: PillarService,
        private sessionService: SessionService,
        private eshotsService: EshotsService
    ) {
        from(this.getSessionStorageProgress())
            .pipe(untilDestroyed(this), take(1))
            .subscribe((storedProgress) => {
                if (storedProgress) {
                    this.setCurrentProgress(storedProgress);
                }
            });
    }

    public setCurrentProgress(progress: PassportProgress): void {
        this.saveProgressToSessionStorage(progress);
        this._passportProgress$.next(progress);
    }

    public resetProgress() {
        if (sessionStorage[this.SESSION_STORAGE_KEY]) {
            sessionStorage.removeItem(this.SESSION_STORAGE_KEY);
            this.setCurrentProgress({});
            this.sessionService.clearSession();
        }
    }

    public async isPassportComplete(): Promise<boolean> {
        const pillars = await this.pillarService.getAllPillars();
        const progress = this._passportProgress$.getValue();

        return pillars.every((pillar) => {
            return pillar.id in progress && progress[pillar.id]?.complete;
        });
    }

    public isQuizComplete(quizId: string): boolean {
        const progress = this._passportProgress$.getValue();

        if (progress) {
            return progress[quizId]?.complete || false;
        }

        return false;
    }

    public async getCompletedQuizzes(): Promise<string[]> {
        const quizPillars = await this.pillarService.getAllPillars();

        return Promise.all(
            quizPillars
                .map(async (pillar) => {
                    if (this.isQuizComplete(pillar.id)) {
                        return pillar.id;
                    }

                    return "";
                })
                .filter(async (id) => {
                    return (await id) !== "";
                })
        );
    }

    public async getCompletedPassport(): Promise<PassportProgress> {
        const pillars = await this.pillarService.getAllPillars();
        const progress = this._passportProgress$.getValue();

        pillars.forEach((pillar) => {
            progress[pillar.id] = { ...progress[pillar.id], complete: true };
        });

        return progress;
    }

    public async getProgressWithUpdatedQuizStatuses(
        answers: Partial<IRegistrationFormData>
    ): Promise<PassportProgress> {
        const pillars = await this.pillarService.getAllPillars();
        const progress: PassportProgress = { ...this._passportProgress$.getValue() };

        pillars.forEach((pillar) => {
            if (pillar.eshotsId in answers) {
                progress[pillar.id] = { complete: true, questionProgress: {} };
            }
        });

        return progress;
    }

    public setQuizToComplete(quizId: string): void {
        const currentProgress = this._passportProgress$.getValue();
        currentProgress[quizId] = { ...currentProgress[quizId], complete: true };

        this.setCurrentProgress(currentProgress);
    }

    public resetQuizCompletionStatus(quizId: string): void {
        const currentProgress = this._passportProgress$.getValue();
        currentProgress[quizId] = { ...currentProgress[quizId], complete: false };

        this.setCurrentProgress(currentProgress);
    }

    public getCompletedQuizIds(): Observable<string[]> {
        return this.getCurrentProgress().pipe(
            map((currentProg) => {
                const completedQuizIds: string[] = [];

                Object.entries(currentProg).forEach((quiz) => {
                    const [quizId, quizProgress] = quiz;

                    if (quizProgress.complete) {
                        completedQuizIds.push(quizId);
                    }
                });

                return completedQuizIds;
            })
        );
    }

    public getCurrentProgress(): Observable<PassportProgress> {
        return this._passportProgress$.asObservable();
    }

    public async setProgressFromSession(): Promise<void> {
        const session = await firstValueFrom(this.sessionService.getSession());

        if (session?.email) {
            const res = await firstValueFrom(this.eshotsService.getConsumer(session.email));
            if (res && res.consumer && res.consumer.answers) {
                const progress = await this.getProgressFromApi(res.consumer.answers);
                this.setCurrentProgress(progress);
            } else {
                return this.setCurrentProgress({});
            }
        } else {
            this.resetProgress();
        }
    }

    private async getProgressFromApi(consumerAnswers: Partial<IRegistrationFormData>): Promise<PassportProgress> {
        if (consumerAnswers.sweepstake_complete) {
            return await this.getCompletedPassport();
        } else {
            return await this.getProgressWithUpdatedQuizStatuses(consumerAnswers);
        }
    }

    public async setProgressFromApi(consumerAnswers: Partial<IRegistrationFormData>): Promise<void> {
        const apiProgress = await this.getProgressFromApi(consumerAnswers);
        this.setCurrentProgress(apiProgress);
    }

    public async getSessionStorageProgress(): Promise<PassportProgress | undefined> {
        const storedProgress = sessionStorage.getItem(this.SESSION_STORAGE_KEY);

        if (storedProgress) {
            try {
                const data = JSON.parse(storedProgress) as PassportProgress;
                if (await this.isValidPassportProgress(data)) {
                    return data;
                } else {
                    this.resetProgress();
                    return {};
                }
            } catch (error) {
                console.error(error);
            }
        }

        return undefined;
    }

    private saveProgressToSessionStorage(progress: PassportProgress) {
        try {
            const content = JSON.stringify(progress);

            sessionStorage.setItem(this.SESSION_STORAGE_KEY, content);
        } catch (error) {
            console.error(error);
        }
    }

    private async isValidPassportProgress(progress: PassportProgress): Promise<boolean> {
        if (!progress) {
            return false;
        }

        return Object.entries(progress).every((entry) => {
            const [quizId, quizProgress] = entry;
            return this.isValidQuizProgress(quizId, quizProgress.questionProgress || {});
        });
    }

    private async isValidQuizProgress(quizId: string, quizProgress: QuestionProgressMap): Promise<boolean> {
        if (!quizId || !quizProgress) {
            return false;
        }

        return Object.values(quizProgress).every(async (questionProgress) => {
            return await this.questionService.isValidQuestionProgress(quizId, questionProgress);
        });
    }

    public async getNextQuestionId(quizId: string): Promise<string> {
        const quiz = await this.quizLoader.getQuizById(quizId);

        if (!quiz) {
            return "";
        }

        const questionIds = quiz?.questions.map((question) => question.id);

        const lastCompletedQuestion = await this.getLastCompletedQuestionId(quizId, questionIds);

        const locationInArray = questionIds.indexOf(lastCompletedQuestion);
        if (locationInArray < questionIds.length - 1) {
            return questionIds[locationInArray + 1];
        }

        return questionIds[0];
    }

    private async getLastCompletedQuestionId(quizId: string, questionIds: string[]): Promise<string> {
        return await questionIds.reduce(async (accumulator, currentValue) => {
            const complete = await this.isQuestionComplete(quizId, currentValue);

            return complete ? currentValue : accumulator;
        }, Promise.resolve(""));
    }

    private async isQuestionComplete(quizId: string, questionId: string): Promise<boolean> {
        const currentProgress = this._passportProgress$.getValue();

        if (!currentProgress) {
            return false;
        }

        const quizProgress = currentProgress[quizId];

        if (quizProgress && quizProgress.questionProgress) {
            const questionProgress = quizProgress.questionProgress[questionId] || null;

            if (questionProgress) {
                return true;
            }
        }

        return false;
    }
}
