import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "src/app/shared/shared.module";
import { AnswerTextPipe } from "./answer-text.pipe";
import { DisableVoiOptionPipe } from "./disable-voi-option.pipe";
import { ImageChoiceComponent } from "./image-choice/image-choice.component";
import { RegistrationQuestionnaireComponent } from "./registration-questionnaire.component";
import { VoiTypePipe } from "./voi-type.pipe";
import { VoiOptionSelectedPipe } from "./voiOptionSelected.pipe";

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, SharedModule],
    declarations: [
        RegistrationQuestionnaireComponent,
        VoiTypePipe,
        DisableVoiOptionPipe,
        VoiOptionSelectedPipe,
        AnswerTextPipe,
        ImageChoiceComponent
    ],
    exports: [RegistrationQuestionnaireComponent, VoiTypePipe, DisableVoiOptionPipe, VoiOptionSelectedPipe]
})
export class RegistrationQuestionnaireModule {}
