import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { IRegistrationQuestionnaireAnswer } from "src/app/common/models/RegistrationQuestionnaire.model";

@Component({
    selector: "app-image-choice",
    templateUrl: "./image-choice.component.html",
    styleUrls: ["./image-choice.component.scss"]
})
export class ImageChoiceComponent implements OnInit {
    @Output() choiceSelected: EventEmitter<string> = new EventEmitter<string>();

    @HostBinding("class.selected") @Input() selected: boolean = false;

    @Input() answer!: IRegistrationQuestionnaireAnswer;

    @Input() disabled: boolean = false;

    constructor() {}

    ngOnInit(): void {}

    public handleClick(): void {
        if (!this.answer || this.disabled) {
            return;
        }

        this.choiceSelected.emit(this.answer.keyword);
    }
}
