import { DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, TitleStrategy } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TemplatePageTitleStrategy } from "./common/providers/template-page-title-strategy.provider";
import { ClearProgressComponent } from "./components/clear-progress/clear-progress.component";
import { CompleteComponent } from "./components/complete/complete.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { HomeComponent } from "./components/home/home.component";
import { HowItWorksComponent } from "./components/how-it-works/how-it-works.component";
import { IneligibleComponent } from "./components/ineligible/ineligible.component";
import { OfficialRulesComponent } from "./components/official-rules/official-rules.component";
import { QrModalComponent } from "./components/qr-modal/qr-modal.component";
import { RegisterModule } from "./components/register/register.module";
import { RegistrationQuestionnaireModule } from "./components/registration-questionnaire/registration-questionnaire.module";
import { SentryTestComponent } from "./components/sentry-test/sentry-test.component";
import { SharedModule } from "./shared/shared.module";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HowItWorksComponent,
        DashboardComponent,
        QrModalComponent,
        SentryTestComponent,
        ClearProgressComponent,
        IneligibleComponent,
        CompleteComponent,
        OfficialRulesComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        ZXingScannerModule,
        HttpClientModule,
        ReactiveFormsModule,
        RegisterModule,
        RegistrationQuestionnaireModule
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
                logErrors: environment.name === "Local"
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true
        },
        {
            provide: TitleStrategy,
            useClass: TemplatePageTitleStrategy
        },
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
