<div class="page">
    <div class="page-hero">
        <h3 class="page-hero__title text--lg">Thank you for registering with Toyota!</h3>
        <p class="page-hero__copy text--md">You are all set to play in the Passport Challenge and enter for a chance to win a 2023 Toyota Crown.</p>
    </div>

    <div class="c-slider">
        <h2 class="text--3xl text--centered slider-heading">How it works</h2>

        <swiper #swiperSlider class="slider slider--how-it-works" [config]="swiperConfig"
            (slideChange)="onSlideChange($event)">
            <ng-template swiperSlide>
                <div class="slide step-1">
                    <img class="circle" src="assets/icons/icon-qr.svg" alt="QR code graphic">
                    <h4 class="step-number text--sm uppercase">Step 1</h4>
                    <hr>
                    <p class="slide__copy text--sm">
                        Explore the Toyota auto show space to find the Passport Challenge branded QR codes. Scan the code to activate each destination.
                    </p>
                </div>
            </ng-template>
            <ng-template swiperSlide>
                <div class="slide step-2">
                    <img class="circle" src="assets/icons/icon-book.svg" alt="Open Book">
                    <h4 class="step-number text--sm uppercase">Step 2</h4>
                    <hr>
                    <p class="slide__copy text--sm">
                        Test your Toyota knowledge to<br> complete the destination on<br> 
                        your passport.
                    </p>
                </div>
            </ng-template>
            <ng-template swiperSlide>    
                <div class="slide step-3">
                    <img class="circle" src="assets/icons/icon-circle-checkmark.svg" alt="Checkmark"> 
                    <h4 class="step-number text--sm uppercase">Step 3</h4>
                    <hr>
                    <p class="slide__copy text--sm">
                        Visit all 4 destinations to enter for a chance to win a 2023 Toyota Crown.
                    </p>
                </div>
            </ng-template>

        </swiper>
    </div>
    <div class="footer-button">
        <app-button text="Go To Your Dashboard" (buttonClick)="goToDashboard()"></app-button>
    </div>
</div>