<div class="nav">
    <div class="header header--nav">
        <div class="logo">
            <img class="logo__img" src="assets/images/logos/logo-toyota-passport-white.svg" alt="Toyota Passport Logo" />
        </div>
        <app-svg-button 
            fileName="icon-close.svg" 
            customClass="icon-button--close"
            (buttonClick)="close()"
        >
        </app-svg-button>
    </div>
    <div class="nav__body">
        <ul class="nav__links">
            <li class="nav-link">
                <app-link 
                    (linkClicked)="handleLinkClick('NAV_MENU_HOW_IT_WORKS')"
                    path="how-it-works"
                    customClass="text--3xl text--white"
                    text="How it works">
                </app-link>
            </li>
            <li class="nav-link nav-link--dashboard">
                <app-link
                    (linkClicked)="handleLinkClick('NAV_MENU_DASHBOARD'); goToPillar()"
                    customClass="text--3xl text--white"
                    path="dashboard"
                    text="Your Dashboard"
                >
                </app-link>
            </li>
            <li class="nav-link nav-link--pillar" *ngFor="let pillar of pillars">
                <app-link
                    (linkClicked)="goToPillar(pillar)"
                    customClass="text--lg text--white"
                    path="dashboard"
                    [text]="pillar.name"
                    [activeStateAllowed]="false"
                >
                </app-link>
                <span *ngIf="pillar.id | stringInList: completedQuizIds" class="completed-icon"></span>
            </li>
        </ul>
        <div class="nav__footer">
            <app-link
                (linkClicked)="openOfficialRules()"
                customClass="text--white uppercase"
                class="rules"
                path="rules"
                text="Official Rules"
            >
            </app-link>
        </div>
    </div>
</div>